import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "../../../components/utils/loggedin";
import Account from "./account";
import Security from "./security";
import Billing from "./billing";
import Notification from "./notification";

import Helmet from "react-helmet";

export default function ViewAdmin() {
  const [tab, setTab] = useState("account");
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useAuth();
  const [adminData, setAdminData] = useState({});
  const [loading, setLoading] = useState(true); // Loading state added
  const [otp, setOtp] = useState("");

  useEffect(() => {
    const { adminId } = location.state;
    if (!adminId) {
      navigate("/admins");
    }
    fetchAdminData();
  }, []);

  const fetchAdminData = async () => {
    try {
      const { adminId } = location.state;
      setLoading(true); // Set loading to true before fetching data
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/getadmin/${adminId}`,
        {
          headers: {
            Signature: process.env.REACT_APP_API_SIGNATURE,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAdminData(response.data);
    } catch (error) {
      console.error("Error fetching admin data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  function handleTab(tab) {
    setTab(tab);
  }

  return (
    <>
      <Helmet>
        <title>Admin View page</title>
      </Helmet>
      <div className="row gy-6 gy-md-0">
        {loading ? (
          // Loader UI
          <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            {/* User Sidebar */}
            <div className="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0">
              {/* User Card */}
              <div className="card mb-6">
                <div className="card-body pt-12">
                  <div className="user-avatar-section">
                    <div className=" d-flex align-items-center flex-column">
                      <img
                        className="img-fluid rounded-3 mb-4"
                        src="/img/avatars/1.png"
                        height={120}
                        width={120}
                        alt="User avatar"
                      />
                      <div className="user-info text-center">
                        <h5>{adminData.first_name + " " + adminData.last_name}</h5>
                        <span className="badge bg-label-primary rounded-pill">
                          {adminData.admin_type}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*/ User Sidebar */}
            {/* User Content */}
            <div className="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1">
              {/* User Tabs */}
              <div className="nav-align-top">
                <ul className="nav nav-pills flex-column flex-md-row mb-6 row-gap-2">
                  <li className="nav-item">
                    <button
                      onClick={() => handleTab("account")}
                      className={`btn nav-link ${tab === "account" && "active"}`}
                    >
                      <i className="ri-group-line me-2" />
                      Account
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      onClick={() => handleTab("security")}
                      className={`btn nav-link ${tab === "security" && "active"}`}
                    >
                      <i className="ri-lock-2-line me-2" />
                      Password
                    </button>
                  </li>
                  {adminData.admin_type == "clinicadmin" ||
                    adminData.admin_type == "salesadmin" ? (
                    <li className="nav-item">
                      <button
                        onClick={() => handleTab("billing")}
                        className={`btn nav-link ${tab === "billing" && "active"}`}
                      >
                        <i className="ri-id-card-line me-2"></i>
                        Cards
                      </button>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
              {/*/ User Tabs */}
              {tab === "account" && (
                <Account fetchAdminData={fetchAdminData} admin={adminData} />
              )}
              {tab === "security" && <Security admin={adminData} />}
              {tab === "billing" && <Billing admin={adminData} />}
              {tab === "notification" && <Notification />}
            </div>
            {/*/ User Content */}
          </>
        )}
      </div>
    </>
  );
}
