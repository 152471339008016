import React from 'react'
import SuperAdminDashboard from './superadmin'
import { useAuth } from '../../components/utils/loggedin'
import SalesAdminDashboard from './salesadmin';
import DoctorAdmin from './doctoradmin'

export default function Index() {
  const { admin } = useAuth();
  return (
    <>
      {admin?.admin_type === "superadmin" && <SuperAdminDashboard />}
      {admin?.admin_type === "salesadmin" && <SalesAdminDashboard />}
      {admin?.admin_type==="doctoradmin" && <DoctorAdmin/>}
    </>
  )
}
