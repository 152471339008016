import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function AllSalesReps() {
    const [salesreps, setSalesReps] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize] = useState(10); // Number of items per page
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSalesReps = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/salesrep/getallsalesreps`, {
                    headers: {
                        "Signature": process.env.REACT_APP_API_SIGNATURE
                    },
                    params: {
                        page: currentPage,
                        size: pageSize
                    }
                });
                setSalesReps(response.data.salesreps); // Assuming the API response has a 'salesreps' field
                setTotalPages(response.data.totalPages); // Assuming the API response has a 'totalPages' field
            } catch (error) {
                console.error('Error fetching salesreps:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSalesReps();
    }, [currentPage, pageSize]);

    function handlePageChange(page) {
        setCurrentPage(page);
    }

    function ViewSalesRep(salesrepId) {
        navigate('/admins/salesreps/view', { state: { salesrepId } });
    }

    return (
        <>
            <h2 className="mb-4">Sales Representatives</h2>
            <div className="row g-6 mb-6">
                {/* Summary Cards */}
                <div className="col-sm-6 col-xl-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div className="me-1">
                                    <p className="text-heading mb-1">Total SalesReps</p>
                                    <div className="d-flex align-items-center">
                                        <h4 className="mb-1 me-2">{salesreps.length}</h4>
                                    </div>
                                    <small className="mb-0">All SalesReps</small>
                                </div>
                                <div className="avatar">
                                    <div className="avatar-initial bg-label-primary rounded-3">
                                        <div className="ri-group-line ri-26px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div className="me-1">
                                    <p className="text-heading mb-1">Pending Verification</p>
                                    <div className="d-flex align-items-center">
                                        <h4 className="mb-1 me-1">{salesreps.filter(salesrep => !salesrep.verified).length}</h4>
                                    </div>
                                </div>
                                <div className="avatar">
                                    <div className="avatar-initial bg-label-danger rounded-3">
                                        <div className="ri-user-add-line ri-26px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div className="me-1">
                                    <p className="text-heading mb-1">Active SalesReps</p>
                                    <div className="d-flex align-items-center">
                                        <h4 className="mb-1 me-1">{salesreps.filter(salesrep => salesrep.onboarded).length}</h4>
                                    </div>
                                </div>
                                <div className="avatar">
                                    <div className="avatar-initial bg-label-success rounded-3">
                                        <div className="ri-user-follow-line ri-26px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div className="me-1">
                                    <p className="text-heading mb-1">Pending Onboarding</p>
                                    <div className="d-flex align-items-center">
                                        <h4 className="mb-1 me-1">{salesreps.filter(salesrep => !salesrep.onboarded).length}</h4>
                                    </div>
                                </div>
                                <div className="avatar">
                                    <div className="avatar-initial bg-label-warning rounded-3">
                                        <div className="ri-user-search-line ri-26px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* SalesReps List Table */}
            <div className="card">
                <div className="card-header border-bottom">
                    <h5 className="card-title mb-0">Filters</h5>
                    <div className="d-flex justify-content-between align-items-center row gx-5 pt-4 gap-5 gap-md-0">
                        <div className="col-md-4 user_role" />
                        <div className="col-md-4 user_plan" />
                        <div className="col-md-4 user_status" />
                    </div>
                </div>
                <div className="card-datatable table-responsive">
                    <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                        <div className="row p-4">
                            <div className="col-md-2 d-flex align-items-center justify-content-md-start justify-content-center">
                                <div className="dt-action-buttons mt-5 mt-md-0">
                                    <div className="dt-buttons btn-group flex-wrap">
                                        <div className="btn-group">
                                            <div className="btn-group">
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary buttons-collection dropdown-toggle btn-outline-secondary waves-effect waves-light"
                                                    data-bs-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <span>
                                                        <span className="d-flex align-items-center">
                                                            <i className="ri-download-2-line ri-16px me-2" />{" "}
                                                            <span className="d-none d-sm-inline-block">Export</span>
                                                        </span>{" "}
                                                    </span>
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                    <a className="dropdown-item" href="#">
                                                        <i className="ri-file-pdf-line ri-16px me-2" /> PDF
                                                    </a>
                                                    <a className="dropdown-item" href="#">
                                                        <i className="ri-file-excel-2-line ri-16px me-2" /> Excel
                                                    </a>
                                                </div>
                                            </div>
                                        </div>{" "}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-10">
                                <div className="d-flex align-items-center justify-content-md-end justify-content-center">
                                    <div className="me-4">
                                        <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                            <label>
                                                <input
                                                    type="search"
                                                    className="form-control form-control-sm"
                                                    placeholder="Search SalesRep"
                                                    aria-controls="DataTables_Table_0"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Username</th>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Status</th>
                                    <th>Onboarded</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan="7">Loading...</td>
                                    </tr>
                                ) : (
                                    salesreps.map((salesrep, index) => (
                                        <tr key={index}>
                                            <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                            <td>{salesrep.username}</td>
                                            <td>{salesrep.first_name} {salesrep.last_name}</td>
                                            <td>{salesrep.phone}</td>
                                            <td className={salesrep.verified ? "text-success" : "text-danger"}>
                                                {salesrep.verified ? (
                                                    <span className="badge bg-label-success rounded-pill">Verified</span>
                                                ) : (
                                                    <span className="badge bg-label-danger rounded-pill">Not Verified</span>
                                                )}
                                            </td>
                                            <td className={salesrep.onboarded ? "text-success" : "text-warning"}>
                                                {salesrep.onboarded ? (
                                                    <span className="badge bg-label-success rounded-pill">Onboarded</span>
                                                ) : (
                                                    <span className="badge bg-label-warning rounded-pill">Not Onboarded</span>
                                                )}
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                    <button
                                                        type="button"
                                                        className="btn p-0 dropdown-toggle hide-arrow"
                                                        data-bs-toggle="dropdown"
                                                    >
                                                        <i className="ri-more-2-line" />
                                                    </button>
                                                    <div className="dropdown-menu">
                                                        <button
                                                            onClick={() => ViewSalesRep(salesrep.id)}
                                                            className="dropdown-item waves-effect"
                                                        >
                                                            <i className="ri-eye-line me-2" /> View
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                        {/* Pagination Controls */}
                        <nav aria-label="Page navigation">
                            <ul className="pagination">
                                <li className="page-item">
                                    <button
                                        className="page-link"
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    >
                                        Previous
                                    </button>
                                </li>
                                {[...Array(totalPages)].map((_, i) => (
                                    <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                        <button
                                            className="page-link"
                                            onClick={() => handlePageChange(i + 1)}
                                        >
                                            {i + 1}
                                        </button>
                                    </li>
                                ))}
                                <li className="page-item">
                                    <button
                                        className="page-link"
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                    >
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
}
