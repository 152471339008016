import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../components/utils/loggedin';
import { toast } from 'react-toastify';
import Account from './account';
import Security from './security';
import Billing from './billing';

export default function ViewAdmin() {
    const [tab, setTab] = useState("account");
    const location = useLocation();
    const navigate = useNavigate();
    const [clinicData, setClinicData] = useState({});
    const { token } = useAuth();

    useEffect(() => {
        if (!location.state || !location.state.clinicId) {
            navigate('/admins/clinics');
            return;
        }
        fetchClinicData();
    }, []);

    const fetchClinicData = async () => {
        try {
            const { clinicId } = location.state;
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/clinic/getclinic/${clinicId}`, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE
                }
            });

            setClinicData(response.data);
        } catch (error) {
            console.error('Error fetching clinic data:', error);
        }
    }

    const onBoardClinc = async (id) => {
        const confirm = window.confirm('Are you sure you want to onboard this clinic?');
        if (!confirm) {
            return;
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/clinic/onboardclinic/${id}`, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                toast.success(response.data.message);
                fetchClinicData();
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'An error occurred. Please try again later.');
        }
    }

    function handleTab(tab) {
        setTab(tab);
    }

    return (
        <>
            <div className="row gy-6 gy-md-0">
                {/* User Sidebar */}
                <div className="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0">
                    {/* User Card */}
                    <div className="card mb-6">
                        <div className="card-body pt-12">
                            <div className="user-avatar-section">
                                <div className=" d-flex align-items-center flex-column">
                                    <img
                                        className="img-fluid rounded-3 mb-4"
                                        src="/img/avatars/1.png"
                                        height={120}
                                        width={120}
                                        alt="User avatar"
                                    />
                                    <div className="user-info text-center">
                                        <h5>{clinicData.first_name + ' ' + clinicData.last_name}</h5>
                                        <span className="badge bg-label-primary rounded-pill">
                                            {clinicData.admin_type}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*/ User Sidebar */}
                {/* User Content */}
                <div className="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1">
                    {/* User Tabs */}
                    <div className="nav-align-top">
                        <ul className="nav nav-pills flex-column flex-md-row mb-6 row-gap-2">
                            <li className="nav-item">
                                <button onClick={() => handleTab("account")} className={`btn nav-link ${tab === 'account' && 'active'}`}>
                                    <i className="ri-group-line me-2" />
                                    Account
                                </button>
                            </li>
                            <li className="nav-item">
                                <button onClick={() => handleTab("security")} className={`btn nav-link ${tab === 'security' && 'active'}`}>
                                    <i className="ri-lock-2-line me-2" />
                                    Security
                                </button>
                            </li>
                            <li className="nav-item">
                                <button onClick={() => handleTab("billing")} className={`btn nav-link ${tab === 'billing' && 'active'}`}>
                                    <i className="ri-id-card-line me-2"></i>
                                    Cards
                                </button>
                            </li>
                        </ul>
                    </div>
                    {/*/ User Tabs */}
                    {(tab === 'account') && <Account clinic={clinicData} fetchClinicData={fetchClinicData} />}
                    {(tab === 'security') && <Security clinic={clinicData} />}
                    {(tab === 'billing') && <Billing clinic={clinicData} />}
                </div>
                {/*/ User Content */}
            </div>
        </>
    )
}
