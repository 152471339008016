import React, { useState, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/auth/login";
import Profile from "./pages/profile";
import Dashboard from "./pages/dashboard";
import Clinics from "./pages/clinics";
import AddClinic from "./pages/clinics/addclinic";
import Header from "./components/layout/header";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import VerifyClinicOTP from "./pages/clinics/verifyotp";
import ViewClinic from "./pages/clinics/viewclinic";
import { useAuth } from "./components/utils/loggedin";
import HealthCard from "./pages/cards";
import SalesReps from "./pages/sales";
import AddSalesRep from "./pages/sales/addsalesrep";
import ViewSalesRep from "./pages/sales/profile";
import VerifySalesOTP from "./pages/sales/verifyotp";
import Doctors from "./pages/doctors";
import AddDoctor from "./pages/doctors/adddoctor";
import ViewDoctor from "./pages/doctors/viewdoctor";
import VerifyDoctorOTP from "./pages/doctors/verifyotp";
import Admins from "./pages/admins";
import AddAdmin from "./pages/admins/addadmin";
import ViewAdmin from "./pages/admins/profile";
import VerifyAdminOTP from "./pages/admins/verifyotp";
import AllSalesReps from "./pages/superadmin/sales/allsalesreps";
import ViewSalesRepAdmin from "./pages/superadmin/sales/profile";
import ViewClinicAdmin from "./pages/superadmin/clinics/profile";
import AllClinic from "./pages/superadmin/clinics/allclincs";
import AllDoctors from "./pages/superadmin/doctors/alldoctors";
import ViewDoctorAdmin from "./pages/superadmin/doctors/profile";
import Patient from "./pages/patient";
import ViewPatientAdmin from "./pages/patient/profile";
import { ColorRing } from "react-loader-spinner";
import Testimonials from "./pages/testimonials";
import FAQ from "./pages/faqs";
import AllotedCards from "./pages/sales/allotedcard";

export default function App() {
  const { isAuthenticated } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: "rgba(255, 255, 255, 0.8)", // Optional: to create a light overlay
          zIndex: 9999,
        }}
      >
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "30px", marginBottom: "20px" }}>Loading...</span>
          <ColorRing
            visible={true}
            height="100"
            width="100"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <BrowserRouter>
          <Routes>
            {isAuthenticated ? (
              <>
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route
                  path="*"
                  element={
                    <Header>
                      <h1>Page Not Found 404</h1>
                    </Header>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <Header>
                      <Dashboard />
                    </Header>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <Header>
                      <Profile />
                    </Header>
                  }
                />
                <Route
                  path="/clinics"
                  element={
                    <Header>
                      <Clinics />
                    </Header>
                  }
                />
                <Route
                  path="/clinics/add"
                  element={
                    <Header>
                      <AddClinic />
                    </Header>
                  }
                />
                <Route
                  path="/clinics/view"
                  element={
                    <Header>
                      <ViewClinic />
                    </Header>
                  }
                />
                <Route
                  path="/clinics/verify/otp"
                  element={
                    <Header>
                      <VerifyClinicOTP />
                    </Header>
                  }
                />
                <Route
                  path="/sales"
                  element={
                    <Header>
                      <SalesReps />
                    </Header>
                  }
                />
                <Route
                  path="/sales/add"
                  element={
                    <Header>
                      <AddSalesRep />
                    </Header>
                  }
                />
                <Route
                  path="/sales/allotedcards"
                  element={
                    <Header>
                      <AllotedCards />
                    </Header>
                  }
                />
                <Route
                  path="/sales/view"
                  element={
                    <Header>
                      <ViewSalesRep />
                    </Header>
                  }
                />
                <Route
                  path="/sales/verify/otp"
                  element={
                    <Header>
                      <VerifySalesOTP />
                    </Header>
                  }
                />
                <Route
                  path="/doctors"
                  element={
                    <Header>
                      <Doctors />
                    </Header>
                  }
                />
                <Route
                  path="/doctors/add"
                  element={
                    <Header>
                      <AddDoctor />
                    </Header>
                  }
                />
                <Route
                  path="/doctors/view"
                  element={
                    <Header>
                      <ViewDoctor />
                    </Header>
                  }
                />
                <Route
                  path="/doctors/verify/otp"
                  element={
                    <Header>
                      <VerifyDoctorOTP />
                    </Header>
                  }
                />
                <Route
                  path="/admins"
                  element={
                    <Header>
                      <Admins />
                    </Header>
                  }
                />
                <Route
                  path="/admins/add"
                  element={
                    <Header>
                      <AddAdmin />
                    </Header>
                  }
                />
                <Route
                  path="/admins/view"
                  element={
                    <Header>
                      <ViewAdmin />
                    </Header>
                  }
                />
                <Route
                  path="/admins/verify/otp"
                  element={
                    <Header>
                      <VerifyAdminOTP />
                    </Header>
                  }
                />
                <Route
                  path="/admins/salesreps"
                  element={
                    <Header>
                      <AllSalesReps />
                    </Header>
                  }
                />
                <Route
                  path="/admins/salesreps/view"
                  element={
                    <Header>
                      <ViewSalesRepAdmin />
                    </Header>
                  }
                />
                <Route
                  path="/admins/clinics"
                  element={
                    <Header>
                      <AllClinic />
                    </Header>
                  }
                />
                <Route
                  path="/admins/clinics/view"
                  element={
                    <Header>
                      <ViewClinicAdmin />
                    </Header>
                  }
                />
                <Route
                  path="/admins/doctors"
                  element={
                    <Header>
                      <AllDoctors />
                    </Header>
                  }
                />
                <Route
                  path="/admins/doctors/view"
                  element={
                    <Header>
                      <ViewDoctorAdmin />
                    </Header>
                  }
                />
                <Route
                  path="/cards"
                  element={
                    <Header>
                      <HealthCard />
                    </Header>
                  }
                />
                <Route
                  path="/admins/patients"
                  element={
                    <Header>
                      <Patient />
                    </Header>
                  }
                />
                <Route
                  path="/admins/patient/view"
                  element={
                    <Header>
                      <ViewPatientAdmin />
                    </Header>
                  }
                />
                <Route
                  path="/admins/testimonials"
                  element={
                    <Header>
                      <Testimonials />
                    </Header>
                  }
                />
                <Route
                  path="/admins/faqs"
                  element={
                    <Header>
                      <FAQ />
                    </Header>
                  }
                />

              </>
            ) : (
              <>
                {/* Route specifically for /login */}
                <Route path="/login" element={<Login />} />

                {/* Wildcard route to redirect all other paths to /login */}
                <Route path="*" element={<Navigate to="/login" replace />} />
              </>
            )}
          </Routes>
          <ToastContainer />
        </BrowserRouter>
      </div>
    );
  }
}
