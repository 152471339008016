import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../components/utils/loggedin';

export default function ViewClinic() {
    const location = useLocation();
    const navigate = useNavigate();
    const { token } = useAuth();
    const [clinicData, setClinicData] = useState({});
    const [otp, setOtp] = useState('');
    useEffect(() => {
        const { clinicId } = location.state;
        if (!clinicId) {
            navigate('/clinics');
        }
        fetchClinicData();
    }, []);

    const fetchClinicData = async () => {
        try {
            const { clinicId } = location.state;
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/clinic/getclinic/${clinicId}`, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE
                }
            });
            setClinicData(response.data);
        } catch (error) {
            console.error('Error fetching clinic data:', error);
        }
    }

    const initiateVerification = async (phone) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/clinic/initiateclinicverification`, {
                phone
            }, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE
                }
            });
            if (response.status === 200) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const verifyClinic = async (e) => {
        e.preventDefault()
        console.log(otp, clinicData.phone)
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/clinic/verifyclinic`, {
                phone: clinicData.phone,
                otp
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Signature": process.env.REACT_APP_API_SIGNATURE
                }
            });
            if (response.status === 200) {
                toast.success(response.data.message);
                fetchClinicData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <div className="flex align-items-center justify-content-center  row gy-6 gy-md-0">
                {/* User Sidebar */}
                <div className="col-xl-6 col-lg-5 col-md-5 order-1 order-md-0">
                    {/* User Card */}
                    <div className="card mb-6">
                        <div className="card-body pt-12">
                            <div className="user-avatar-section">
                                <div className=" d-flex align-items-center flex-column">
                                    <img
                                        className="img-fluid rounded-3 mb-4"
                                        src="./img/avatars/1.png"
                                        height={120}
                                        width={120}
                                        alt="User avatar"
                                    />
                                    <div className="user-info text-center">
                                        <h5>{clinicData.first_name + ' ' + clinicData.last_name}</h5>
                                        <span className="badge bg-label-success rounded-pill">
                                            Clinic Repersentative
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="d-flex justify-content-around flex-wrap my-6 gap-0 gap-md-3 gap-lg-4">
                                <div className="d-flex align-items-center me-5 gap-4">
                                    <div className="avatar">
                                        <div className="avatar-initial bg-label-primary rounded-3">
                                            <i className="ri-check-line ri-24px" />
                                        </div>
                                    </div>
                                    <div>
                                        <h5 className="mb-0">1.23k</h5>
                                        <span>Task Done</span>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center gap-4">
                                    <div className="avatar">
                                        <div className="avatar-initial bg-label-primary rounded-3">
                                            <i className="ri-briefcase-line ri-24px" />
                                        </div>
                                    </div>
                                    <div>
                                        <h5 className="mb-0">568</h5>
                                        <span>Project Done</span>
                                    </div>
                                </div>
                            </div> */}
                            <h5 className="pb-4 border-bottom mb-4">Details</h5>
                            <div className="info-container">
                                <ul className="list-unstyled mb-6">
                                    <li className="mb-2">
                                        <span className="fw-medium text-heading me-2">Username:</span>
                                        <span>{clinicData.username}</span>
                                    </li>
                                    <li className="mb-2">
                                        <span className="fw-medium text-heading me-2">Email:</span>
                                        <span>{clinicData.email}</span>
                                    </li>
                                    <li className="mb-2">
                                        <span className="fw-medium text-heading me-2">Status:</span>
                                        <span className={`badge bg-label-${clinicData.verified ? 'success' : 'danger'} rounded-pill`}>
                                            {clinicData.verified ? 'Verified' : 'Not Verified'}
                                        </span>
                                        {
                                            !clinicData.verified && <button onClick={() => initiateVerification(clinicData.phone)} data-bs-target="#verifyClinicModal" data-bs-toggle="modal" className="btn text-primary">Verify?</button>
                                        }
                                    </li>
                                    <li className="mb-2">
                                        <span className="fw-medium text-heading me-2">Role:</span>
                                        <span>Clinic Representative</span>
                                    </li>
                                    <li className="mb-2">
                                        <span className="fw-medium text-heading me-2">Contact:</span>
                                        <span>{clinicData.phone}</span>
                                    </li>
                                    <li className="mb-2">
                                        <span className="fw-medium text-heading me-2">Address:</span>
                                        <span>{clinicData.address}, {clinicData.city}, {clinicData.state}, {clinicData.zip}</span>
                                    </li>
                                </ul>
                                {/* <div className="d-flex justify-content-center">
                                    <a
                                        href="javascript:;"
                                        className="btn btn-primary me-4"
                                        data-bs-target="#editUser"
                                        data-bs-toggle="modal"
                                    >
                                        Edit
                                    </a>
                                    <a
                                        href="javascript:;"
                                        className="btn btn-outline-danger suspend-user"
                                    >
                                        Suspend
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {/* /User Card */}
                </div>
            </div>
            {/* Modal */}
            {/* Edit User Modal */}
            <div className="modal fade" id="editUser" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-lg modal-simple modal-edit-user">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                        <div className="modal-body p-0">
                            <div className="text-center mb-6">
                                <h4 className="mb-2">Edit User Information</h4>
                                <p className="mb-6">
                                    Updating user details will receive a privacy audit.
                                </p>
                            </div>
                            <form id="editUserForm" className="row g-5" onsubmit="return false">
                                <div className="col-12 col-md-6">
                                    <div className="form-floating form-floating-outline">
                                        <input
                                            type="text"
                                            id="modalEditUserFirstName"
                                            name="modalEditUserFirstName"
                                            className="form-control"
                                            defaultValue="Oliver"
                                            placeholder="Oliver"
                                        />
                                        <label htmlFor="modalEditUserFirstName">First Name</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-floating form-floating-outline">
                                        <input
                                            type="text"
                                            id="modalEditUserLastName"
                                            name="modalEditUserLastName"
                                            className="form-control"
                                            defaultValue="Queen"
                                            placeholder="Queen"
                                        />
                                        <label htmlFor="modalEditUserLastName">Last Name</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating form-floating-outline">
                                        <input
                                            type="text"
                                            id="modalEditUserName"
                                            name="modalEditUserName"
                                            className="form-control"
                                            defaultValue="oliver.queen"
                                            placeholder="oliver.queen"
                                        />
                                        <label htmlFor="modalEditUserName">Username</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-floating form-floating-outline">
                                        <input
                                            type="text"
                                            id="modalEditUserEmail"
                                            name="modalEditUserEmail"
                                            className="form-control"
                                            defaultValue="oliverqueen@gmail.com"
                                            placeholder="oliverqueen@gmail.com"
                                        />
                                        <label htmlFor="modalEditUserEmail">Email</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-floating form-floating-outline">
                                        <select
                                            id="modalEditUserStatus"
                                            name="modalEditUserStatus"
                                            className="form-select"
                                            aria-label="Default select example"
                                        >
                                            <option value={1} selected="">
                                                Active
                                            </option>
                                            <option value={2}>Inactive</option>
                                            <option value={3}>Suspended</option>
                                        </select>
                                        <label htmlFor="modalEditUserStatus">Status</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-floating form-floating-outline">
                                        <input
                                            type="text"
                                            id="modalEditTaxID"
                                            name="modalEditTaxID"
                                            className="form-control modal-edit-tax-id"
                                            placeholder="123 456 7890"
                                        />
                                        <label htmlFor="modalEditTaxID">Tax ID</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="input-group input-group-merge">
                                        <span className="input-group-text">US (+1)</span>
                                        <div className="form-floating form-floating-outline">
                                            <input
                                                type="text"
                                                id="modalEditUserPhone"
                                                name="modalEditUserPhone"
                                                className="form-control phone-number-mask"
                                                defaultValue="+1 609 933 4422"
                                                placeholder="+1 609 933 4422"
                                            />
                                            <label htmlFor="modalEditUserPhone">Phone Number</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-floating form-floating-outline">
                                        <input
                                            id="TagifyLanguageSuggestion"
                                            name="TagifyLanguageSuggestion"
                                            className="form-control h-auto"
                                            placeholder="select language"
                                            defaultValue="English"
                                        />
                                        <label htmlFor="TagifyLanguageSuggestion">Language</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-floating form-floating-outline">
                                        <select
                                            id="modalEditUserCountry"
                                            name="modalEditUserCountry"
                                            className="select2 form-select"
                                            data-allow-clear="true"
                                        >
                                            <option value="">Select</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Belarus">Belarus</option>
                                            <option value="Brazil">Brazil</option>
                                            <option value="Canada">Canada</option>
                                            <option value="China">China</option>
                                            <option value="France">France</option>
                                            <option value="Germany">Germany</option>
                                            <option value="India" selected="">
                                                India
                                            </option>
                                            <option value="Indonesia">Indonesia</option>
                                            <option value="Israel">Israel</option>
                                            <option value="Italy">Italy</option>
                                            <option value="Japan">Japan</option>
                                            <option value="Korea">Korea, Republic of</option>
                                            <option value="Mexico">Mexico</option>
                                            <option value="Philippines">Philippines</option>
                                            <option value="Russia">Russian Federation</option>
                                            <option value="South Africa">South Africa</option>
                                            <option value="Thailand">Thailand</option>
                                            <option value="Turkey">Turkey</option>
                                            <option value="Ukraine">Ukraine</option>
                                            <option value="United Arab Emirates">
                                                United Arab Emirates
                                            </option>
                                            <option value="United Kingdom">United Kingdom</option>
                                            <option value="United States">United States</option>
                                        </select>
                                        <label htmlFor="modalEditUserCountry">Country</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-check form-switch">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="editBillingAddress"
                                        />
                                        <label htmlFor="editBillingAddress" className="text-heading">
                                            Use as a billing address?
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 text-center d-flex flex-wrap justify-content-center gap-4 row-gap-4">
                                    <button type="submit" className="btn btn-primary">
                                        Submit
                                    </button>
                                    <button
                                        type="reset"
                                        className="btn btn-outline-secondary"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/*/ Edit User Modal */}
            {/* Verify Clinic */}
            <div
                className="modal fade"
                id="verifyClinicModal"
                tabIndex={-1}
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-simple modal-upgrade-plan">
                    <div className="modal-content p-8 p-md-12">
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                        <div className="modal-body pt-md-0 px-0">
                            <div className="text-center mb-6">
                                <h4 className="mb-2">Verify OTP</h4>
                                <p className="mb-10">Enter the OTP sent to your phone number.</p>
                            </div>
                            <form
                                id="verifyOTPForm"
                                className="row g-4 d-flex align-items-center"
                                onsubmit="return false"
                            >
                                <div className="col-sm-9">
                                    <input
                                        onChange={(e) => setOtp(e.target.value)}
                                        type="number"
                                        id="otp"
                                        name="otp"
                                        className="form-control form-control-sm"
                                        aria-label="Enter OTP"
                                        maxLength="6"
                                    />
                                </div>
                                <div className="col-sm-3 d-flex align-items-end">
                                    <button onClick={(e) => verifyClinic(e)} type="submit" className="btn btn-primary">
                                        Verify
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/*/ Verify Clinic */}
            {/* /Modal */}
        </>

    )
}
