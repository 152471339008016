import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../../components/utils/loggedin";

export default function HealthCard({ user }) {
  const [card, setCard] = useState(null);
  const [loading, setLoading] = useState(true);
  const { token } = useAuth();

  const fetchCard = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/healthcard/getcard/${user.id}`,
        {
          headers: {
            Signature: process.env.REACT_APP_API_SIGNATURE,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        console.log("API Response:", response.data); // Verify the structure of the response
        const { card_details } = response.data;
        if (card_details.aadhaar_card_numbers.length > 0) {
          setCard(card_details);
        } else {
          setCard(null); // Set card to null if Aadhaar numbers are empty
        }
      } else {
        console.log("Failed to fetch Smart Health Card");
      }
    } catch (error) {
      console.log(
        `Error: ${error.response ? error.response.data.message : "An error occurred"}`
      );
      setCard(null); // Set card to null if an error occurs
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCard();
  }, []);

  const renderDetail = (label, value) => (
    <React.Fragment key={label}>
      <dt className="col-sm-5 col-xxl-4 mb-2 text-nowrap fw-medium text-heading">
        {label}
      </dt>
      <dd className="col-sm-7 col-xxl-8">
        {value ? value : "Not Available"}
      </dd>
    </React.Fragment>
  );

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : card ? (
        <div className="card card-action mb-6">
          <div className="card-header align-items-center border-bottom">
            <h5 className="card-action-title mb-0">Smart Health Card</h5>
          </div>
          <div className="card-body mt-2">
            <div className="row">
              <div className="col-xl-7 col-12">
                <dl className="row mb-0 gx-2">
                  {renderDetail("Card Number", card.card_number)}
                  {renderDetail(
                    "Aadhaar Numbers",
                    card.aadhaar_card_numbers.length > 0
                      ? card.aadhaar_card_numbers.join(", ")
                      : "No Aadhaar Numbers Available"
                  )}
                  {renderDetail("Type", card.type)}
                  {renderDetail("Admin Type", card.admin_type)}
                  {renderDetail(
                    "Status",
                    card.activated ? "Activated" : "Not Activated"
                  )}
                  {renderDetail(
                    "Generated On",
                    card.created_at ? new Date(card.created_at).toLocaleDateString("en-us", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }) : "Not Available"
                  )}
                  {renderDetail(
                    "Validity",
                    card.validity ? new Date(card.validity).toLocaleDateString("en-us", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }) : "Not Available"
                  )}
                </dl>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>No data found</div>
      )}
    </>
  );
}
