import AboutAdmin from './aboutadmin'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../components/utils/loggedin';
import Security from './reset-password';
import { Helmet } from 'react-helmet';
export default function Profile() {

    const { token } = useAuth()
    const [activeTab, setActiveTab] = useState("profile");
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const [adminData, setAdminData] = useState({
        username: '',
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        is_phone_verified: false,
        phone_otp: '',
        phone_otp_expiry: '',
        admin_type: '',
        verified: false,
        created_at: null
    });

    const fetchAdminData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/getadmin`, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    "Authorization": `Bearer ${token}`
                }
            });
            const data = response.data;
            setAdminData({
                id: data.id,
                username: data.username,
                first_name: data.first_name,
                last_name: data.last_name,
                phone: data.phone,
                profile_img: data.profile_img,
                email: data.email,
                address: data.address,
                city: data.city,
                state: data.state,
                zip: data.zip,
                is_phone_verified: data.is_phone_verified,
                phone_otp: data.phone_otp,
                phone_otp_expiry: data.phone_otp_expiry,
                admin_type: data.admin_type,
                verified: data.verified,
                created_at: data.created_at
            });
        } catch (error) {
            console.error('There was an error fetching the admin data!', error);
        }
    };



    useEffect(() => {
        fetchAdminData();
    }, [token]);
    return (
        <>
            <Helmet>
                <title>
                    Profile - Superadmin BharateClinics
                </title>
            </Helmet>
            {/* Header */}
            <div className="row">
                <div className="col-12">
                    <div className="card mb-6">
                        <div className="user-profile-header-banner">
                            <img
                                src="./img/pages/profile-banner.png"
                                alt="Banner image"
                                className="rounded-top"
                            />
                        </div>
                        <div className="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-5">
                            <div className="flex-shrink-0 mt-n2 mx-sm-0 mx-auto">
                                <img
                                    src={adminData.profile_img}
                                    alt="user image"
                                    className="d-block h-auto ms-0 ms-sm-5 rounded-4 user-profile-img"
                                />
                            </div>
                            <div className="flex-grow-1 mt-4 mt-sm-12">
                                <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-5 flex-md-row flex-column gap-6">
                                    <div className="user-profile-info">
                                        <h4 className="mb-2 text-capitalize">{adminData.first_name} {adminData.last_name}</h4>
                                        <ul className="list-inline mb-0 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-4">
                                            <li className="list-inline-item">
                                                <i className="ri-admin-line me-2 ri-24px" />
                                                <span className="fw-medium">{adminData.admin_type === "superadmin" ? "Super Admin" : adminData.admin_type === "salesadmin" ? "Sales Admin" : adminData.admin_type === "clinicadmin" ? "Clinic Admin" : "Doctor Admin"}</span>
                                            </li>
                                            <li className="list-inline-item">
                                                <i className="ri-map-pin-line me-2 ri-24px" />
                                                <span className="fw-medium">{adminData.city}</span>
                                            </li>
                                            <li className="list-inline-item">
                                                <i className="ri-calendar-line me-2 ri-24px" />
                                                <span className="fw-medium">Joined {new Date(adminData.created_at).toLocaleString('default', { month: 'long' }) + ' ' + new Date(adminData.created_at).getFullYear()}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <a href="javascript:void(0)" className={`btn btn-${adminData.verified ? 'success' : 'outline-success'}`}>
                                        <i className="ri-user-follow-line ri-16px me-2" />
                                        {adminData.verified ? 'Verified' : 'Not Verified'}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*/ Header */}
            {/* User Profile Content */}
            <div className="row">
                <div className="col-xl-6 col-lg-5 col-md-5">
                    <div className="nav-align-top">
                        <ul className="nav nav-pills flex-column flex-sm-row mb-6">
                            {/* Profile Tab */}
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${activeTab === 'profile' ? 'active' : ''}`}
                                    href="javascript:void(0);"
                                    onClick={() => handleTabClick('profile')}
                                >
                                    <i className="ri-user-3-line me-2" />
                                    Profile
                                </a>
                            </li>



                            {/* password  */}
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${activeTab === 'security' ? 'active' : ''}`}
                                    href="javascript:void(0);"
                                    onClick={() => handleTabClick('security')}
                                >
                                    <i className="ri-lock-password-line me-2"></i>
                                    Security
                                </a>
                            </li>
                        </ul>
                    </div>
                    {/* About User */}
                    {/*/ About User */}
                    {activeTab === "profile" && <AboutAdmin adminData={adminData} />}
                    {activeTab === "security" && <Security adminData={adminData} />}
                </div>
            </div>
            {/*/ User Profile Content */}
        </>

    )
}
