import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function AllSalesReps() {
    const [salesreps, setSalesReps] = useState([]);
    const [filteredSalesReps, setFilteredSalesReps] = useState([]);
    const [loading, setLoading] = useState(true);

    const [salesRepsPerPage, setSalesRepsPerPage] = useState(10);
    const [searchName, setSearchName] = useState('');
    const [searchUsername, setSearchUsername] = useState('');
    const [searchAdmin, setSearchAdmin] = useState('');
    const [searchPhone, setSearchPhone] = useState('');
    const [filterOnboarded, setFilterOnboarded] = useState('All');
    const [searchStatus, setSearchStatus] = useState('All');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limit, setLimit] = useState(10);
    const pages = 10;
    // New state for status filter
    const navigate = useNavigate();

    const fetchSalesReps = (page = 1) => {
        axios.get(`${process.env.REACT_APP_API_URL}/salesrep/getallsalesreps?page=${page}&limit=${limit}&username=${searchUsername}&name=${searchName}&phone=${searchPhone}&adminName=${searchAdmin}`, {
            headers: {
                "Signature": process.env.REACT_APP_API_SIGNATURE
            }
        })
            .then(response => {
                setSalesReps(response.data.salesReps);
                setCurrentPage(page);
                setTotalPages(response?.data?.pagination.totalPages);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching salesreps:', error);
                setLoading(false);
            });
    }

    const renderPagination = () => {
        const pageNumbers = [];
        const maxPageButtons = 5;
        let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
        let endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

        if (endPage - startPage < maxPageButtons - 1) {
            startPage = Math.max(endPage - maxPageButtons + 1, 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(i)}>
                        {i}
                    </button>
                </li>
            );
        }

        const handlePageChange = (page) => {
            if (page < 1 || page > totalPages) return;
            fetchSalesReps(page);
        };

        return (
            <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                        <i className="tf-icon ri-arrow-left-s-line ri-20px" />
                    </button>
                </li>
                {startPage > 1 && (
                    <>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(1)}>1</button>
                        </li>
                        <li className="page-item disabled"><span className="page-link">...</span></li>
                    </>
                )}
                {pageNumbers}
                {endPage < totalPages && (
                    <>
                        <li className="page-item disabled"><span className="page-link">...</span></li>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
                        </li>
                    </>
                )}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                        <i className="tf-icon ri-arrow-right-s-line ri-20px" />
                    </button>
                </li>
            </ul>
        );
    };

    useEffect(() => {
        fetchSalesReps();
    }, [limit, searchUsername, searchName, searchStatus, searchPhone, searchAdmin])


    function ViewSalesRep(salesrepId) {
        if (!salesrepId) {
            navigate('/admins/salesreps');
        }
        navigate('/admins/salesreps/view', { state: { salesrepId } });
    }

    return (
        <>
            <Helmet>
                <title>Manage SalesReps Page</title>
            </Helmet>


            {/* Show per page dropdown */}


            {/* Users List Table */}
            <div className="card">
                <h5 className="card-title p-4 mb-0">Sales Representative</h5>

                <div className="d-flex justify-content-between align-items-center p-4 gap-3">
                    <label className="d-flex align-items-center mb-0">
                        Show
                        <select
                            className="form-select form-select-sm ms-2 me-2"
                            value={limit}
                            onChange={(e) => setLimit(parseInt(e.target.value, 10))}
                            style={{ width: "auto" }}
                        >

                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={75}>75</option>
                            <option value={100}>100</option>
                        </select>
                        entries
                    </label>


                </div>
                <div className="card-datatable table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>
                                    Username
                                </th>
                                <th>
                                    SalesAdmin
                                </th>
                                <th>
                                    Name
                                </th>
                                <th>
                                    Phone
                                </th>
                                <th>Status</th>
                                <th>
                                    Onboarded
                                </th>
                                <th>Action</th>
                            </tr>
                            {/* Search Inputs */}
                            <tr>
                                <td></td>
                                <td>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Search by username"
                                        value={searchUsername}
                                        onChange={(e) => setSearchUsername(e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Search by Admin name"
                                        value={searchAdmin}
                                        onChange={(e) => setSearchAdmin(e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Search by name"
                                        value={searchName}
                                        onChange={(e) => setSearchName(e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Search by phone"
                                        value={searchPhone}
                                        onChange={(e) => setSearchPhone(e.target.value)}
                                    />
                                </td>
                                <td>
                                    <select
                                        className="form-select form-select-sm"
                                        value={searchStatus}
                                        onChange={(e) => setSearchStatus(e.target.value)}
                                    >
                                        <option value="All">All</option>
                                        <option value="Verified">Verified</option>
                                        <option value="Not Verified">Not Verified</option>
                                    </select>
                                </td>
                                <td>
                                    <select
                                        className="form-select form-select-sm"
                                        value={filterOnboarded}
                                        onChange={(e) => setFilterOnboarded(e.target.value)}
                                    >
                                        <option value="All">All</option>
                                        <option value="Onboarded">Onboarded</option>
                                        <option value="Not Onboarded">Not Onboarded</option>
                                    </select>
                                </td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="7">Loading...</td>
                                </tr>
                            ) : (
                                salesreps.length > 0 ? (
                                    salesreps.map((salesrep, index) => (
                                        <tr key={index}>
                                            <td>{(currentPage - 1) * pages + index + 1}</td>
                                            <td>{salesrep.username === "" ? "BHSRXXXX" : salesrep.username}</td>
                                            <td>{salesrep.sales_admin_name}</td>
                                            <td>{salesrep.first_name} {salesrep.last_name}</td>
                                            <td>{salesrep.phone}</td>
                                            <td className={salesrep.verified ? "text-success" : "text-danger"}>
                                                {salesrep.verified ? <span className="badge bg-label-success rounded-pill">Verified</span> : <span className="badge bg-label-danger rounded-pill">Not Verified</span>}
                                            </td>
                                            <td className={salesrep.onboarded ? "text-success" : "text-warning"}>
                                                {salesrep.onboarded ? <span className="badge bg-label-success rounded-pill">Onboarded</span> : <span className="badge bg-label-warning rounded-pill">Not Onboarded</span>}
                                            </td>
                                            <td>
                                                <button className='btn' onClick={() => ViewSalesRep(salesrep.id)}>
                                                    <i className="ri-eye-line"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="7" className="text-center">No Data Found</td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                    <div className="row mt-3">
                        <nav aria-label="Page navigation">
                            {renderPagination()}
                        </nav>
                    </div>
                </div>

            </div>
        </>
    );
}
