import { Link } from "react-router-dom";
import AllAdminss from "./alladmins";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../components/utils/loggedin";
import { Helmet } from "react-helmet";

export default function Admins() {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token } = useAuth();
  useEffect(() => {
    if (token) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/admin/getalladmins`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Signature: process.env.REACT_APP_API_SIGNATURE,
          },
        })
        .then((response) => {
          setAdmins(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching admins:", error);
          setLoading(false);
        });
    }
  }, [token]);
  return (
    <>
      <Helmet>
        <title>Manage Admin Page</title>
      </Helmet>
      <div className="row g-6 mb-6">
        <div className="col-sm-6 col-xl-3">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <div className="me-1">
                  <p className="text-heading mb-1">Total Admins</p>
                  <div className="d-flex align-items-center">
                    <h4 className="mb-1 me-2">{admins.length}</h4>
                  </div>
                </div>
                <div className="avatar">
                  <div className="avatar-initial bg-label-primary rounded-3">
                    <div className="ri-group-line ri-26px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-3">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <div className="me-1">
                  <p className="text-heading mb-1">Unverified</p>
                  <div className="d-flex align-items-center">
                    <h4 className="mb-1 me-1">
                      {/* {admins.filter((admin) => !admin.verified).length} */}
                    </h4>
                  </div>
                </div>
                <div className="avatar">
                  <div className="avatar-initial bg-label-danger rounded-3">
                    <div className="ri-close-line ri-26px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-3">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <div className="me-1">
                  <p className="text-heading mb-1">Active Admins</p>

                  <div className="d-flex align-items-center">
                    <h4 className="mb-1 me-1">
                      {/* {admins.filter((admin) => admin.verified).length} */}
                    </h4>
                  </div>
                </div>
                <div className="avatar">
                  <div className="avatar-initial bg-label-success rounded-3">
                    <div className="ri-user-follow-line ri-26px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Users List Table */}
      <div className="card">
        <div className="d-flex justify-content-between card-header border-bottom">
          <h5 className="card-title mb-0">Admins</h5>

          <div className="add-new">
            <Link
              to="/admins/add"
              className="btn btn-label-primary waves-effect waves-light"
            >
              <i className="ri-add-line me-0 me-sm-1 d-inline-block d-sm-none"></i>
              <span className="d-none d-sm-inline-block">
                <i className="ri-add-line"></i> Add New Admin
              </span>
            </Link>
          </div>
        </div>

        <div className="card-datatable table-responsive">
          <div
            id="DataTables_Table_0_wrapper"
            className="dataTables_wrapper dt-bootstrap5 no-footer"
          >
            <AllAdminss admins={admins} loading={loading} />
          </div>
        </div>
      </div>
    </>
  );
}
