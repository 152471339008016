import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../../components/utils/loggedin';

export default function Cards({ salesRepData }) {
    const { token } = useAuth();

    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        totalItems: 0,
        currentPage: 1,
        pageSize: 10,
        totalPages: 1
    });
    const [filter, setFilter] = useState('');
    const [totalActivated, setTotalActivated] = useState(0);
    const [totalNotActivated, setTotalNotActivated] = useState(0);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(-2);
        return `${day}/${month}/${year}`;
    };

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/healthcard/salesrep/details/${salesRepData.id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            Signature: process.env.REACT_APP_API_SIGNATURE,
                        },
                        params: {
                            page: pagination.currentPage,
                            pageSize: pagination.pageSize,
                            filter: filter
                        }
                    }
                );
                setCards(response.data.cards || []);
                setPagination({
                    ...pagination,
                    totalItems: response.data.pagination.totalItems,
                    totalPages: response.data.pagination.totalPages
                });
                setTotalActivated(response.data.totalActivated || 0);
                setTotalNotActivated(response.data.totalNotActivated || 0);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        }

        fetchData();
    }, [pagination.currentPage, pagination.pageSize, filter]);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= pagination.totalPages) {
            setPagination({
                ...pagination,
                currentPage: newPage
            });
        }
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
        setPagination({
            ...pagination,
            currentPage: 1 // Reset to the first page when filter changes
        });
    };

    function formatDateTime(isoString) {
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return ` ${hours}:${minutes}:${seconds} : ${day}-${month}-${year}`;
    }

    return (
        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Card Details</h5>
            </div>

            <div className="table-responsive text-nowrap">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Card Number</th>
                            <th>Patient Id</th>
                            <th>Alloted Name</th>
                            <th>Activated</th>
                            <th>Created At</th>
                            <th>Validity</th>
                        </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                        {cards.length > 0 ? (
                            cards.map(card => (
                                card.user_details.length > 0 ? (
                                    card.user_details.map(detail => (
                                        <tr key={card.id}>
                                            <td>{card.card_number}</td>
                                            <td>{detail.user.username}</td>
                                            <td>{detail.user.first_name + ' ' + detail.user.last_name}</td>
                                            <td>{card.activated ? "Yes" : "No"}</td>
                                            <td>{formatDateTime(card.created_at)}</td>
                                            <td>{card.validity ? formatDate(card.validity) : "N/A"}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr key={card.id}>
                                        <td>{card.card_number}</td>
                                        <td>BHPTXXXXXX</td>
                                        <td>Details_not_available</td>
                                        <td>{card.activated ? "Yes" : "No"}</td>
                                        <td>Not Found</td>
                                        <td>{card.validity ? formatDate(card.validity) : "N/A"}</td>
                                    </tr>
                                )
                            ))
                        ) : (
                            <tr>
                                <td colSpan={4} className="text-center">No data available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
