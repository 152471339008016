import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../components/utils/loggedin';
import { useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet';



export default function SalesAdminDashboard() {
    const { token } = useAuth();
    const [appointmentsData, setAppointmentsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) return;

        axios.get(`${process.env.REACT_APP_API_URL}/healthcard/cards/statistics`, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Signature": process.env.REACT_APP_API_SIGNATURE
            }
        })
            .then(response => {
                setAppointmentsData(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching appointmentsData:', error);
                setLoading(false);
            });
    }, [token]);

    if (!token || loading) {
        return <div>Loading...</div>; // Show a loading state or redirect to login if not authenticated
    }

    return (
        <>
            <Helmet>
                <title>Dashboard Page - SalesAdmin -Bharateclinics</title>
            </Helmet>
            <div className="row g-6 mb-6">
                <div className="row g-6">
                    <div className="col-sm-6 col-lg-3">
                        <div className="card card-border-shadow-primary h-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="avatar me-4">
                                        <span className="avatar-initial rounded-3 bg-label-primary">
                                            <i className="menu-icon tf-icons ri-group-line"></i>

                                        </span>
                                    </div>
                                    <h4 className="mb-0">{appointmentsData?.totalGenerated}</h4>
                                </div>
                                <h6 className="mb-0 fw-normal">Total Cards</h6>
                                <p className="mb-0">
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <div className="card card-border-shadow-warning h-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="avatar me-4">
                                        <span className="avatar-initial rounded-3 bg-label-warning">
                                            <i className="menu-icon tf-icons ri-calendar-check-line"></i>

                                        </span>
                                    </div>
                                    <h4 className="mb-0">{appointmentsData?.totalNotActivated}</h4>
                                </div>
                                <h6 className="mb-0 fw-normal">Not Activated Cards</h6>
                                <p className="mb-0">
                                    {/* <span className="me-1 fw-medium">-8.7%</span>
                <small className="text-muted">than last week</small> */}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3">
                        <div className="card card-border-shadow-danger h-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="avatar me-4">
                                        <span className="avatar-initial rounded-3 bg-label-danger">
                                            <i className="menu-icon tf-icons ri-close-circle-line"></i>

                                        </span>
                                    </div>
                                    <h4 className="mb-0">{appointmentsData?.totalExpired}</h4>
                                </div>
                                <h6 className="mb-0 fw-normal">Expired Cards</h6>
                                <p className="mb-0">
                                    {/* <span className="me-1 fw-medium">+4.3%</span>
                <small className="text-muted">than last week</small> */}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <div className="card card-border-shadow-success h-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="avatar me-4">
                                        <span className="avatar-initial rounded-3 bg-label-success">
                                            <i className="menu-icon tf-icons ri-checkbox-circle-line"></i>

                                        </span>
                                    </div>
                                    <h4 className="mb-0">{appointmentsData?.totalActivated}</h4>
                                </div>
                                <h6 className="mb-0 fw-normal">Total Activated Cards</h6>
                                <p className="mb-0">
                                    {/* <span className="me-1 fw-medium">-2.5%</span>
                <small className="text-muted">than last week</small> */}
                                </p>
                            </div>
                        </div>
                    </div>
                    {/*/ Card Border Shadow */}

                </div>
                {/* <ShipmentStatisticsChart /> */}
            </div>
        </>
    );
}
