import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/utils/loggedin";
import axios from "axios";

export default function AllPatient({ patients = [], loading }) {
  const navigate = useNavigate();
  const { token } = useAuth();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
  const [searchName, setSearchName] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [searchPhone, setSearchPhone] = useState("");

  // Filter and paginate patients
  const filteredPatients = patients.filter(patient =>
    patient.first_name.toLowerCase().includes(searchName.toLowerCase()) &&
    patient.email.toLowerCase().includes(searchEmail.toLowerCase()) &&
    patient.phone.toLowerCase().includes(searchPhone.toLowerCase())
  );

  const totalPages = Math.ceil(filteredPatients.length / itemsPerPage);
  const indexOfLastPatient = currentPage * itemsPerPage;
  const indexOfFirstPatient = indexOfLastPatient - itemsPerPage;
  const currentPatients = filteredPatients.slice(indexOfFirstPatient, indexOfLastPatient);

  function handleItemsPerPageChange(event) {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page when changing items per page
  }

  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  function ViewPatient(patientId) {
    navigate("/admins/patient/view", { state: { patientId } });
  }

  // Function to format dates as dd/mm/yy
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

    return `${day}/${month}/${year}`;
  };

  return (
    <div>
      {/* Patient Table */}
      <table className="table">
        <thead>
          <tr>
            <td colSpan="7">
              <select
                className="form-select form-select-sm"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                style={{ width: "150px" }}
              >
                <option value={5}>5 per page</option>
                <option value={10}>10 per page</option>
                <option value={15}>15 per page</option>
                <option value={20}>20 per page</option>
              </select>
            </td>
          </tr>
          <tr>
            <th style={{ width: "5%" }}>S.No</th>
            <th style={{ width: "20%" }}>Name</th>
            <th style={{ width: "20%" }}>Email</th>
            <th style={{ width: "15%" }}>Phone</th>
            <th style={{ width: "10%" }}>Created</th>
            <th style={{ width: "10%" }}>Actions</th>
          </tr>
          <tr>
            <td style={{ width: "5%" }}></td>
            <td style={{ width: "20%" }}>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search by name"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
              />
            </td>
            <td style={{ width: "20%" }}>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search by email"
                value={searchEmail}
                onChange={(e) => setSearchEmail(e.target.value)}
              />
            </td>
            <td style={{ width: "15%" }}>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search by phone"
                value={searchPhone}
                onChange={(e) => setSearchPhone(e.target.value)}
              />
            </td>
            <td style={{ width: "10%" }}></td>
            <td style={{ width: "10%" }}></td>

          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="7">Loading...</td>
            </tr>
          ) : currentPatients.length === 0 ? (
            <tr>
              <td colSpan="7" className="text-center text-muted">
                No data found
              </td>
            </tr>
          ) : (
            currentPatients.map((patient, index) => (
              <tr key={patient.id}>
                <td>{indexOfFirstPatient + index + 1}</td>
                <td>
                  {patient.first_name} {patient.last_name}
                </td>
                <td>{patient.email}</td>
                <td>{patient.phone}</td>
                <td>{formatDate(patient.created_at)}</td>

                <td>
                  <button
                    className="btn"
                    onClick={() => ViewPatient(patient.id)}
                  >
                    <i className="ri-eye-line"></i>
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {/* Pagination */}
      <nav
        className="flex justify-content-end mt-5 mx-2"
        aria-label="Page navigation"
      >
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button className="page-link" onClick={() => handlePageChange(1)}>
              <i className="tf-icon ri-skip-back-mini-line ri-20px" />
            </button>
          </li>
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <i className="tf-icon ri-arrow-left-s-line ri-20px" />
            </button>
          </li>
          {Array.from({ length: totalPages }, (_, index) => (
            <li
              key={index + 1}
              className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            </li>
          ))}
          <li
            className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}
          >
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <i className="tf-icon ri-arrow-right-s-line ri-20px" />
            </button>
          </li>
          <li
            className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}
          >
            <button
              className="page-link"
              onClick={() => handlePageChange(totalPages)}
            >
              <i className="tf-icon ri-skip-forward-mini-line ri-20px" />
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
}
