import React, { useEffect, useState } from 'react'
import DeleteFAQ from './delete_faq';
import EditFAQ from './edit_faq';
import AddFAQ from './add_faq';

export default function AllFAQs({ faqs, fetchFAQs }) {
    const [selectedFAQ, setSelectedFAQ] = useState(null);
    useEffect(() => {
        fetchFAQs();
    }, [])
    return (
        <div className="row g-6">
            <div className="col-12">
                {/* Role Table */}
                <div className="card">
                    <div className="card-datatable table-responsive">
                        <div className="d-flex justify-content-between align-items-center p-2 py-4 mt-3 mt-md-0">
                            <h5 className="card-title">FAQs</h5>
                            <button
                                data-bs-target="#addNewFAQ"
                                data-bs-toggle="offcanvas"
                                className="btn btn-label-primary text-nowrap add-new-role"
                            >
                                <i className='ri-add-line me-2'></i>  Add FAQ
                            </button>
                            <AddFAQ fetchFAQs={fetchFAQs} />
                        </div>
                        <table className="datatables-users table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Question</th>
                                    <th>Answer</th>
                                    <th className="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {faqs.map((faq, index) => (
                                    <tr key={faq.id}>
                                        <td>{index + 1}</td>
                                        <td>{faq.question.split(' ').slice(0, 5).join(' ')}..</td>
                                        <td>{faq.answer.split(' ').slice(0, 5).join(' ')}..</td>
                                        <td className="text-center">
                                            <button onClick={() => setSelectedFAQ(faq)} data-bs-target="#editFAQ" data-bs-toggle="offcanvas" className="btn text-primary" ><i className='ri-edit-line me-2'></i></button>
                                            <button onClick={() => setSelectedFAQ(faq)} data-bs-target="#deleteFAQ" data-bs-toggle="modal" className="btn text-danger" ><i className='ri-delete-bin-line me-2'></i></button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <DeleteFAQ fetchFAQs={fetchFAQs} faq={selectedFAQ} />
                        <EditFAQ fetchFAQs={fetchFAQs} faq={selectedFAQ} />
                    </div>
                </div>
            </div>
        </div>
    )
}
