import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/utils/loggedin";
import axios from "axios";
import { Helmet } from "react-helmet";

export default function AllAdmins({ admins, loading }) {
  const navigate = useNavigate();
  const pages = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchUsername, setSearchUsername] = useState("");
  const [searchAdminType, setSearchAdminType] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchPhone, setSearchPhone] = useState("");
  const [searchVerified, setSearchVerified] = useState("");
  const [adminData, setAdminData] = useState([]);
  const [searchLoading, setSearchLoading] = useState(loading);
  const [noDataFound, setNoDataFound] = useState(false);
  const [adminsPerPage, setAdminsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);


  const { token } = useAuth();

  // Fetch initial admin data or update when props change
  useEffect((page = 1) => {
    if (token) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/admin/getalladmins?page=${page}&limit=${limit}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Signature: process.env.REACT_APP_API_SIGNATURE,
          },
        })
        .then((response) => {
          setAdminData(response.data.admins);
          setSearchLoading(false);
          setCurrentPage(page);
          setTotalPages(response?.data?.pagination.totalPages);
        })
        .catch((error) => {
          console.error("Error fetching admins:", error);
          setSearchLoading(false);
        });
    }
  }, [token, limit]);

  // Update admin data when search parameters change
  useEffect(() => {
    handleSearch();
  }, [
    searchUsername,
    searchName,
    searchAdminType,
    searchPhone,
    searchVerified,
  ]);

  // const totalPages = Math.ceil(adminData.length / adminsPerPage);

  // const indexOfLastAdmin = currentPage * adminsPerPage;
  // const indexOfFirstAdmin = indexOfLastAdmin - adminsPerPage;
  // const currentAdmins = Array.isArray(adminData)
  //   ? adminData.slice(indexOfFirstAdmin, indexOfLastAdmin)
  //   : [];

  function ViewAdmin(adminId) {
    navigate("/admins/view", { state: { adminId } });
  }

  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  const handleSearch = async () => {
    setSearchLoading(true);
    setNoDataFound(false);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/search`,
        {
          params: {
            username: searchUsername,
            name: searchName,
            admin_type: searchAdminType,
            phone: searchPhone,
            verified: searchVerified !== "" ? searchVerified : undefined, // Ensure that no value means "all"
          },
          headers: {
            Authorization: `Bearer ${token}`,
            Signature: process.env.REACT_APP_API_SIGNATURE,
          },
        }
      );

      console.log("Search API Response:", response.data); // Log search API response
      if (response.status === 200) {
        setAdminData(response.data.admins);
        if (response.data.admins.length === 0) {
          setNoDataFound(true);
        }
      } else {
        setNoDataFound(true);
      }
    } catch (error) {
      console.error("Error during search:", error);
      setAdminData([]);
      setNoDataFound(true);
    } finally {
      setSearchLoading(false);
    }
  };

  const renderPagination = () => {
    const pageNumbers = [];
    const maxPageButtons = 5;
    let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
    let endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

    if (endPage - startPage < maxPageButtons - 1) {
      startPage = Math.max(endPage - maxPageButtons + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }

    const handlePageChange = (page) => {
      if (page < 1 || page > totalPages) return;
      setAdminData(page);
    };

    return (
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
            <i className="tf-icon ri-arrow-left-s-line ri-20px" />
          </button>
        </li>
        {startPage > 1 && (
          <>
            <li className="page-item">
              <button className="page-link" onClick={() => handlePageChange(1)}>1</button>
            </li>
            <li className="page-item disabled"><span className="page-link">...</span></li>
          </>
        )}
        {pageNumbers}
        {endPage < totalPages && (
          <>
            <li className="page-item disabled"><span className="page-link">...</span></li>
            <li className="page-item">
              <button className="page-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
            </li>
          </>
        )}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
            <i className="tf-icon ri-arrow-right-s-line ri-20px" />
          </button>
        </li>
      </ul>
    );
  };

  // Reset currentPage if it exceeds totalPages after filtering
  useEffect(() => {
    if (currentPage > 1 && currentPage > totalPages) {
      setCurrentPage(1);
    }
  }, [currentPage, totalPages]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = String(date.getFullYear()).slice(-2); // Last two digits of the year
    return `${day}/${month}/${year}`;
  }



  return (
    <>
      <Helmet>Main Admin</Helmet>
      <div className="d-flex justify-content-between align-items-center p-4 gap-3">
        <label className="d-flex align-items-center mb-0">
          Show
          <select
            name="DataTables_Table_0_length"
            aria-controls="DataTables_Table_0"
            className="form-select form-select-sm ms-2 me-2"
            value={limit}
            onChange={(e) => setLimit(parseInt(e.target.value, 10))}
            style={{ width: "auto" }} // Adjust width if necessary
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={75}>75</option>
            <option value={100}>100</option>
          </select>
          entries
        </label>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th style={{ width: "5%" }}>S.No</th>
            <th>Username</th>
            <th>Name</th>
            <th>Admin Type</th>
            <th>Phone</th>
            <th>Status</th>
            <th>Created</th>
            <th>Actions</th>

          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSearch();
                }}
                className="d-flex flex-wrap gap-3"
              >
                <div className="form-floating form-floating-outline">
                  <input
                    type="search"
                    className="form-control form-control-sm"
                    placeholder="Search Username"
                    aria-controls="DataTables_Table_0"
                    value={searchUsername}
                    onChange={(e) => setSearchUsername(e.target.value)}
                  />
                  <label htmlFor="search-username"> Username</label>
                </div>
              </form>
            </td>
            <td>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSearch();
                }}
                className="d-flex flex-wrap gap-3"
              >
                <div className="form-floating form-floating-outline">
                  <input
                    type="search"
                    className="form-control form-control-sm"
                    placeholder="Search Name"
                    aria-controls="DataTables_Table_0"
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                  />
                  <label htmlFor="search-name"> Name</label>
                </div>
              </form>
            </td>
            <td>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSearch();
                }}
                className="d-flex flex-wrap gap-3"
              >
                <div className="form-floating form-floating-outline">
                  <input
                    type="search"
                    className="form-control form-control-sm"
                    placeholder="Search Admin Type"
                    aria-controls="DataTables_Table_0"
                    value={searchAdminType}
                    onChange={(e) => setSearchAdminType(e.target.value)}
                  />
                  <label htmlFor="search-admin-type"> Admin Type</label>
                </div>
              </form>
            </td>
            <td>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSearch();
                }}
                className="d-flex flex-wrap gap-3"
              >
                <div className="form-floating form-floating-outline">
                  <input
                    type="search"
                    className="form-control form-control-sm"
                    placeholder="Search Phone"
                    aria-controls="DataTables_Table_0"
                    value={searchPhone}
                    onChange={(e) => setSearchPhone(e.target.value)}
                  />
                  <label htmlFor="search-phone"> Phone</label>
                </div>
              </form>
            </td>
            <td>
              <form className="d-flex flex-wrap gap-3 mb-0">
                <div className="form-floating form-floating-outline">
                  <select
                    className="form-select form-control-sm"
                    id="search-status"
                    value={searchVerified}
                    onChange={(e) => setSearchVerified(e.target.value)}
                    style={{ width: "140px" }} // Adjust width if necessary
                  >
                    <option value="">Select Status</option>
                    <option value="true">Verified</option>
                    <option value="false">Unverified</option>
                  </select>
                  <label htmlFor="search-status"> Status</label>
                </div>
              </form>
            </td>
            <td></td>
            <td></td>
          </tr>
          {searchLoading ? (
            <tr>
              <td colSpan="7">Loading...</td>
            </tr>
          ) : noDataFound ? (
            <tr>
              <td colSpan="7" className="text-center text-muted">
                No data found
              </td>
            </tr>
          ) : (
            adminData.map((admin, index) => (
              <tr key={admin.id}>
                <td>{(currentPage - 1) * pages + index + 1}</td>
                <td>{admin.username ? admin.username : 'BHADXXXX'}</td>
                <td>
                  {admin.first_name} {admin.last_name}
                </td>
                <td>{admin.admin_type.toUpperCase()}</td>
                <td>{admin.phone}</td>
                <td className={admin.verified ? "text-success" : "text-danger"}>
                  {admin.verified ? (
                    <span className="badge bg-label-success rounded-pill">
                      Verified
                    </span>
                  ) : (
                    <span className="badge bg-label-danger rounded-pill">
                      Not Verified
                    </span>
                  )}
                </td>
                <td>{formatDate(admin.created_at)}</td>
                <td>
                  <button className="btn" onClick={() => ViewAdmin(admin.id)}>
                    <i className="ri-eye-line"></i>
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="row mt-3">
        <nav aria-label="Page navigation">
          {renderPagination()}
        </nav>
      </div>
      {/* <nav className="h-fit mt-2 mx-2" aria-label="Page navigation">
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            >
              <i className="tf-icon ri-skip-back-mini-line ri-20px" />
            </button>
          </li>
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <i className="tf-icon ri-arrow-left-s-line ri-20px" />
            </button>
          </li>
          {Array.from({ length: totalPages }, (_, index) => (
            <li
              key={index + 1}
              className={`page-item ${currentPage === index + 1 ? "active" : ""
                }`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            </li>
          ))}
          <li
            className={`page-item ${currentPage === totalPages ? "disabled" : ""
              }`}
          >
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <i className="tf-icon ri-arrow-right-s-line ri-20px" />
            </button>
          </li>
          <li
            className={`page-item ${currentPage === totalPages ? "disabled" : ""
              }`}
          >
            <button
              className="page-link"
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            >
              <i className="tf-icon ri-skip-forward-mini-line ri-20px" />
            </button>
          </li>
        </ul>
      </nav> */}
    </>
  );
}
