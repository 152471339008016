import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../components/utils/loggedin";
import axios from "axios";

export default function SalesCards({ sales }) {
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [notfound, setNotFound] = useState(false);
    const { token } = useAuth();
    const [cardsPerPage, setCardsPerPage] = useState(5);

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const fetchCards = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/healthcard/salesrep/${sales.id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            Signature: process.env.REACT_APP_API_SIGNATURE,
                        },
                    }
                );
                if (response.status === 200) {
                    setCards(response.data.data);
                    setLoading(false);
                } else if (response.status === 404) {
                    setCards([]);
                    setNotFound(true);
                    setLoading(false);
                }
            } catch (error) {
                console.error(error);
                setNotFound(true);
                setLoading(false);
            }
        };

        fetchCards();
    }, [sales.id, token]);

    // Calculate the number of activated and not activated cards
    const activatedCards = cards.filter(card => card.activated).length;
    const notActivatedCards = cards.length - activatedCards;

    // Pagination logic
    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentCards = cards.slice(indexOfFirstCard, indexOfLastCard);
    const totalPages = Math.ceil(cards.length / cardsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        if (currentPage > 1 && currentPage > totalPages) {
            setCurrentPage(1);
        }
    }, [currentPage, totalPages]);

    return (
        <>
            {/* Current Plan */}

            <div className="card mb-6">
                <div className="card h-100">
                    <div className="card-body d-flex flex-wrap gap-4">
                        <div className="d-flex align-items-center gap-3">
                            <div className="avatar">
                                <div className="avatar-initial bg-label-primary rounded">
                                    <i className="ri-check-line ri-24px" />
                                </div>
                            </div>
                            <div className="card-info text-success">
                                <h5 className="mb-0">{activatedCards}</h5>
                                <p className="mb-0">Activated Cards</p>
                            </div>
                        </div>

                        <div className="d-flex align-items-center gap-3">
                            <div className="avatar">
                                <div className="avatar-initial bg-label-info rounded">
                                    <i className="ri-close-line" />
                                </div>
                            </div>
                            <div className="card-info text-danger">
                                <h5 className="mb-0">{notActivatedCards}</h5>
                                <p className="mb-0">Not Activated Cards</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-between align-items-center p-4 gap-3 border border-bottom-5">
                    <label className="d-flex align-items-center mb-0">
                        Show
                        <select
                            name="DataTables_Table_0_length"
                            aria-controls="DataTables_Table_0"
                            className="form-select form-select-sm ms-2 me-2"
                            value={cardsPerPage}
                            onChange={(e) => setCardsPerPage(parseInt(e.target.value, 10))}
                            style={{ width: "auto" }} // Adjust width if necessary
                        >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={75}>75</option>
                            <option value={100}>100</option>
                        </select>
                        entries
                    </label>
                </div>

                {/* Cards Table */}
                <table className="table">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Card Number</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Created_At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="5">Loading...</td>
                            </tr>
                        ) : notfound ? (
                            <tr>
                                <td colSpan="5">No Data Found</td>
                            </tr>
                        ) : (
                            currentCards.map((card, index) => (
                                <tr key={index}>
                                    <td>{indexOfFirstCard + index + 1}</td>
                                    <td>{card.card_number}</td>
                                    <td>{card.type}</td>
                                    <td
                                        className={card.activated ? "text-success" : "text-danger"}
                                    >
                                        {card.activated ? (
                                            <span className="badge bg-label-success rounded-pill">
                                                Activated
                                            </span>
                                        ) : (
                                            <span className="badge bg-label-danger rounded-pill">
                                                Not Activated
                                            </span>
                                        )}
                                    </td>
                                    <td>
                                        {new Date(card.created_at).toLocaleDateString("en-us", {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                        })}
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
                <nav className="h-fit mt-4 mx-2" aria-label="Page navigation">
                    <ul className="pagination">
                        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                            <button
                                className="page-link"
                                onClick={() => handlePageChange(1)}
                                disabled={currentPage === 1}
                            >
                                <i className="tf-icon ri-skip-back-mini-line ri-20px" />
                            </button>
                        </li>
                        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                            <button
                                className="page-link"
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                <i className="tf-icon ri-arrow-left-s-line ri-20px" />
                            </button>
                        </li>
                        {Array.from({ length: totalPages }, (_, index) => (
                            <li
                                key={index + 1}
                                className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
                            >
                                <button
                                    className="page-link"
                                    onClick={() => handlePageChange(index + 1)}
                                >
                                    {index + 1}
                                </button>
                            </li>
                        ))}
                        <li
                            className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}
                        >
                            <button
                                className="page-link"
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                <i className="tf-icon ri-arrow-right-s-line ri-20px" />
                            </button>
                        </li>
                        <li
                            className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}
                        >
                            <button
                                className="page-link"
                                onClick={() => handlePageChange(totalPages)}
                                disabled={currentPage === totalPages}
                            >
                                <i className="tf-icon ri-skip-forward-mini-line ri-20px" />
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>

            {/*/ Cards Address */}
        </>
    );
}
