// src/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [admin, setAdmin] = useState(null);

  useEffect(() => {
    const storedToken = Cookies.get('token');
    if (storedToken) {
      setIsAuthenticated(true);
      setToken(storedToken);
    }
  }, []);

  const fetchAdminData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/getadmin`, {
        headers: {
          "Signature": process.env.REACT_APP_API_SIGNATURE,
          "Authorization": `Bearer ${token}`
        }
      });
      setAdmin(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const login = (newToken, admin) => {
    Cookies.set('token', newToken, { expires: 7 });
    setIsAuthenticated(true);
    setToken(newToken);
  };

  const logout = () => {
    Cookies.remove('token');
    setIsAuthenticated(false);
    setToken(null);
  };
  useEffect(() => {
    if (token) {
      fetchAdminData();
    }
  }, [token])

  return (
    <AuthContext.Provider value={{ isAuthenticated, token, login, logout, admin, fetchAdminData }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
