import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../components/utils/loggedin';
import { toast } from 'react-toastify';
import Account from './account';
import Security from './security';


export default function ViewDoctor() {
    const [tab, setTab] = useState("account");
    const location = useLocation();
    const navigate = useNavigate();
    const [doctorData, setDoctorData] = useState({});
    const { token } = useAuth();

    useEffect(() => {
        const doctorId = location.state?.doctorId;  // Safely access doctorId
        if (!doctorId) {
            navigate('/doctors');
        } else {
            fetchDoctorData(doctorId);  // Pass doctorId to fetchDoctorData
        }
    }, [location.state, navigate, token]);

    const fetchDoctorData = async (doctorId) => {  // Accept doctorId as an argument
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/doctor/getdoctor/${doctorId}`, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    Authorization: `Bearer ${token}`,
                }
            });
            console.log("API Response:", response.data.doctor);
            setDoctorData(response.data.doctor);
        } catch (error) {
            console.error('Error fetching doctor data:', error);

        }
    };

    const onboardDoctor = async (id) => {
        const confirm = window.confirm('Are you sure you want to onboard this doctor?');
        if (!confirm) {
            return;
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/doctor/onboarddoctor/${id}`, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                toast.success(response.data.message);
                fetchDoctorData(id);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'An error occurred. Please try again later.');
        }
    };

    function handleTab(tab) {
        setTab(tab);
    }

    return (
        <>
            <div className="row gy-6 gy-md-0">
                {/* Doctor Sidebar */}
                <div className="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0">
                    {/* Doctor Card */}
                    <div className="card mb-6">
                        <div className="card-body pt-12">
                            <div className="user-avatar-section text-center">
                                <img
                                    className="img-fluid rounded-3 mb-4"
                                    src={doctorData.profile_img || "/img/avatars/1.png"} // Fallback to default if no profile image
                                    height={120}
                                    width={120}
                                    alt="Doctor avatar"
                                />
                                <h5>{doctorData.first_name + " " + doctorData.last_name}</h5>
                                {/* Ensure doctorData.specializations is defined before using map */}
                                <div className="mt-2">
                                    {doctorData.specializations && doctorData.specializations.length > 0 ? (
                                        <div className="d-flex flex-wrap justify-content-center">
                                            {doctorData.specializations.map((spec, index) => (
                                                <span
                                                    key={index}
                                                    className="badge bg-label-primary rounded-pill mb-1 me-1"
                                                    style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                    title={spec.specialization} // Show full text on hover
                                                >
                                                    {spec.specialization}
                                                </span>
                                            ))}
                                        </div>
                                    ) : (
                                        <span className="badge bg-label-secondary rounded-pill">No Specializations</span>
                                    )}
                                </div>
                            </div>
                            {/* Doctor Details */}
                        </div>
                    </div>
                </div>
                {/*/ Doctor Sidebar */}

                {/* Doctor Content */}
                <div className="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1">
                    {/* Doctor Tabs */}
                    <div className="nav-align-top">
                        <ul className="nav nav-pills flex-column flex-md-row mb-6 row-gap-2">
                            <li className="nav-item">
                                <button
                                    onClick={() => handleTab("account")}
                                    className={`btn nav-link ${tab === 'account' && 'active'}`}
                                >
                                    <i className="ri-group-line me-2" />
                                    Account
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    onClick={() => handleTab("security")}
                                    className={`btn nav-link ${tab === 'security' && 'active'}`}
                                >
                                    <i className="ri-lock-2-line me-2" />
                                    Security
                                </button>
                            </li>
                        </ul>
                    </div>
                    {/*/ Doctor Tabs */}

                    {/* Tab Content */}
                    {tab === 'account' && <Account doctor={doctorData} fetchDoctorData={fetchDoctorData} />}
                    {tab === 'security' && <Security doctor={doctorData} />}
                </div>
                {/*/ Doctor Content */}
            </div>



        </>
    );
}
