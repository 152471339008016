import React, { useState } from 'react'
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useAuth } from '../../components/utils/loggedin';
import AllFAQs from './all_faq';

export default function FAQ() {
    const { token } = useAuth();
    const [faqs, setFaqs] = useState([]);
    const fetchFAQs = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/faq/getall`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Signature: `${process.env.REACT_APP_API_SIGNATURE}`,
                }
            });
            setFaqs(res.data);
        } catch (error) {
            console.error('Error fetching testimonials:', error);
        }
    }
    return (
        <>
            <Helmet>
                <title>FAQ Content - Datalyte</title>
            </Helmet>
            <AllFAQs faqs={faqs} fetchFAQs={fetchFAQs} />
        </>
    )
}
