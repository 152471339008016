import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../../components/utils/loggedin';

export default function AddFAQ({ fetchFAQs }) {
    const { token } = useAuth();
    const [formData, setFormData] = useState({
        question: '',
        answer: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/faq`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    Signature: `${process.env.REACT_APP_API_SIGNATURE}`,
                    Authorization: `Bearer ${token}`
                }
            });
            fetchFAQs();
            toast.success('FAQ created successfully!');
            await document.getElementById('closeModal').click();
            await document.getElementById('closeCanvas').click();
            setFormData({ question: '', answer: '' });
        } catch (error) {
            console.error('Error creating faq:', error);
            toast.error('An error occurred while creating the faq');
        }
    };

    return (
        <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="addNewFAQ"
            aria-labelledby="offcanvasAddFAQLabel"
        >
            <div className="offcanvas-header border-bottom">
                <h5 id="offcanvasAddFAQLabel" className="offcanvas-answer">
                    Add FAQ
                </h5>
                <button
                    id="closeCanvas"
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                />
            </div>
            <div className="offcanvas-body mx-0 flex-grow-0 h-100">
                <form
                    className="add-new-faq pt-0"
                    id="addNewFAQForm"
                    onSubmit={handleSubmit}
                >
                    <div className="form-floating form-floating-outline mb-3">
                        <textarea
                            type="text"
                            className="form-control"
                            id="add-faq-question"
                            placeholder="What is Datalyte?"
                            name="question"
                            value={formData.question}
                            onChange={handleInputChange}
                            aria-label="Question"
                        ></textarea>
                        <label htmlFor="add-faq-question">Question</label>
                    </div>
                    <div className="form-floating form-floating-outline mb-3">
                        <textarea
                            type="text"
                            className="form-control"
                            id="add-faq-answer"
                            placeholder="Enter your answer here"
                            name="answer"
                            value={formData.answer}
                            onChange={handleInputChange}
                            aria-label="Answer"
                        ></textarea>
                        <label htmlFor="add-faq-answer">Answer</label>
                    </div>
                    <hr />
                    <button
                        type="submit"
                        className="btn btn-success me-sm-3 me-1 data-submit"
                    >
                        Submit
                    </button>
                    <button
                        id="closeModal"
                        type="reset"
                        className="btn btn-outline-secondary"
                        data-bs-dismiss="offcanvas"
                    >
                        Cancel
                    </button>
                </form>
            </div>
        </div>
    );
}
