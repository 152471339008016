import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Account from './account';
import Security from './security';
import Billing from './billing';
import Notification from './notification';
import { useAuth } from '../../../../components/utils/loggedin';
import { Helmet } from 'react-helmet';

export default function ViewSalesRep() {
    const [tab, setTab] = useState("account");
    const location = useLocation();
    const navigate = useNavigate();
    const [salesRepData, setSalesRepData] = useState({});

    const { token } = useAuth();

    useEffect(() => {
        // Check if location.state and salesrepId exist
        const { salesrepId } = location.state || {};
        if (!salesrepId) {
            navigate('/admins/salesreps'); // Redirect if salesrepId is not available
            return;
        }
        fetchSalesRepData(salesrepId);
    }, [location.state, navigate, token]);

    const fetchSalesRepData = async (salesrepId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/salesrep/getsalesrep/${salesrepId}`, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    Authorization: `Bearer ${token}`
                }
            });

            setSalesRepData(response.data);
        } catch (error) {
            console.error('Error fetching salesrep data:', error);
        }
    };

    const onBoardSalesRep = async (id) => {
        const confirm = window.confirm('Are you sure you want to onboard this salesrep?');
        if (!confirm) {
            return;
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/salesrep/onboardsalesrep/${id}`, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                toast.success(response.data.message);
                fetchSalesRepData(id); // Refetch data after onboarding
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'An error occurred. Please try again later.');
        }
    };

    function handleTab(tab) {
        setTab(tab);
    }

    return (
        <>
            <Helmet>
                <title>View Sales Rep</title>
            </Helmet>

            <div className="row gy-6 gy-md-0">
                {/* User Sidebar */}
                <div className="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0">
                    {/* User Card */}
                    <div className="card mb-6">
                        <div className="card-body pt-12">
                            <div className="user-avatar-section">
                                <div className="d-flex align-items-center flex-column">
                                    <img
                                        className="img-fluid rounded-3 mb-4"
                                        src={salesRepData.profile_img ? salesRepData.profile_img : "/img/avatars/1.png"}
                                        height={120}
                                        width={120}
                                        alt="User avatar"
                                    />
                                    <div className="user-info text-center">
                                        <h5>{salesRepData.first_name + ' ' + salesRepData.last_name}</h5>
                                        <span className="badge bg-label-primary rounded-pill">
                                            {salesRepData.admin_type}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /User Card */}
                </div>
                {/*/ User Sidebar */}
                {/* User Content */}
                <div className="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1">
                    {/* User Tabs */}
                    <div className="nav-align-top">
                        <ul className="nav nav-pills flex-column flex-md-row mb-6 row-gap-2">
                            <li className="nav-item">
                                <button onClick={() => handleTab("account")} className={`btn nav-link ${tab === 'account' && 'active'}`}>
                                    <i className="ri-group-line me-2" />
                                    Account
                                </button>
                            </li>
                            <li className="nav-item">
                                <button onClick={() => handleTab("security")} className={`btn nav-link ${tab === 'security' && 'active'}`}>
                                    <i className="ri-lock-2-line me-2" />
                                    Security
                                </button>
                            </li>
                            <li className="nav-item">
                                <button onClick={() => handleTab("billing")} className={`btn nav-link ${tab === 'billing' && 'active'}`}>
                                    <i className="ri-id-card-line me-2"></i>
                                    Cards
                                </button>
                            </li>
                        </ul>
                    </div>
                    {/*/ User Tabs */}
                    {(tab === 'account') && <Account fetchSalesRepData={() => fetchSalesRepData(location.state?.salesrepId)} sales={salesRepData} />}
                    {(tab === 'security') && <Security salesRep={salesRepData} />}
                    {(tab === 'billing') && <Billing sales={salesRepData} />}

                </div>
                {/*/ User Content */}
            </div>
        </>
    );
}
