import { useState } from 'react';
import { useNavigate } from "react-router-dom";

export default function AllClinics({ clinics, loading }) {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const clinicsPerPage = 10; // Number of clinics per page

    // Calculate total pages
    const totalPages = Math.ceil(clinics.length / clinicsPerPage);

    // Get current clinics based on the current page
    const indexOfLastClinic = currentPage * clinicsPerPage;
    const indexOfFirstClinic = indexOfLastClinic - clinicsPerPage;
    const currentClinics = clinics.slice(indexOfFirstClinic, indexOfLastClinic);

    function ViewClinic(clinicId) {
        navigate('/clinics/view', { state: { clinicId } });
    }

    function handlePageChange(pageNumber) {
        setCurrentPage(pageNumber);
    }

    return (
        <div>
            <table className="table">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Status</th>
                        <th>Onboarded</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan="7">Loading...</td>
                        </tr>
                    ) : (
                        currentClinics.map((clinic, index) => (
                            <tr key={clinic.id}>
                                <td>{indexOfFirstClinic + index + 1}</td>
                                <td>{clinic.first_name} {clinic.last_name}</td>
                                <td>{clinic.email}</td>
                                <td>{clinic.phone}</td>
                                <td className={clinic.verified ? "text-success" : "text-danger"}>
                                    {clinic.verified ? (
                                        <span className="badge bg-label-success rounded-pill">Verified</span>
                                    ) : (
                                        <span className="badge bg-label-danger rounded-pill">Not Verified</span>
                                    )}
                                </td>
                                <td className={clinic.onboarded ? "text-success" : "text-warning"}>
                                    {clinic.onboarded ? (
                                        <span className="badge bg-label-success rounded-pill">Onboarded</span>
                                    ) : (
                                        <span className="badge bg-label-warning rounded-pill">Not Onboarded</span>
                                    )}
                                </td>
                                <td>
                                    <div className="dropdown">
                                        <button
                                            type="button"
                                            className="btn p-0 dropdown-toggle hide-arrow"
                                            data-bs-toggle="dropdown"
                                        >
                                            <i className="ri-more-2-line" />
                                        </button>
                                        <div className="dropdown-menu">
                                            <button onClick={() => ViewClinic(clinic.id)} className="dropdown-item waves-effect">
                                                <i className="ri-eye-line me-2" /> View
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>

            {/* Pagination */}
            <nav className="flex justify-content-end mt-5 mx-2" aria-label="Page navigation">
                <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(1)}>
                            <i className="tf-icon ri-skip-back-mini-line ri-20px" />
                        </button>
                    </li>
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                            <i className="tf-icon ri-arrow-left-s-line ri-20px" />
                        </button>
                    </li>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                {index + 1}
                            </button>
                        </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                            <i className="tf-icon ri-arrow-right-s-line ri-20px" />
                        </button>
                    </li>
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(totalPages)}>
                            <i className="tf-icon ri-skip-forward-mini-line ri-20px" />
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    );
}
