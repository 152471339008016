import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useAuth } from "../../components/utils/loggedin";
import { Helmet } from "react-helmet";

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!identifier || !password) {
      toast.error("Phone/Username and password are required");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/login`,
        { identifier, password },
        {
          headers: {
            Signature: process.env.REACT_APP_API_SIGNATURE,
          },
        }
      );
      const { admin, token } = response.data;
      if (response.status === 200) {
        login(token, admin);
        toast.success(`Welcome ${admin.first_name}`);
        navigate("/");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Error logging in");
    }
    setLoading(false);
  };

  return (
    <>

      <Helmet>
        <title>Login Admin - Bharat eClinics</title>
      </Helmet>
      <div className="authentication-wrapper authentication-cover">
        <a href="/" className="auth-cover-brand d-flex align-items-center gap-2">
          <span className="app-brand-logo demo">
            <span style={{ color: "var(--bs-primary)" }}>
              <img height={80} src="./img/logo.png" alt="" />
            </span>
          </span>
          <span className="app-brand-text demo text-heading fw-semibold">
            Bharat eClinics
          </span>
        </a>

        <div className="authentication-inner row m-0">
          <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center justify-content-center p-12 pb-2">
            <img
              src="./img/illustrations/auth-login-illustration-light.png"
              className="auth-cover-illustration w-100"
              alt="auth-illustration"
              data-app-light-img="illustrations/auth-login-illustration-light.png"
              data-app-dark-img="illustrations/auth-login-illustration-dark.html"
            />
            <img
              src="./img/illustrations/auth-cover-login-mask-light.png"
              className="authentication-image"
              alt="mask"
              data-app-light-img="illustrations/auth-cover-login-mask-light.png"
              data-app-dark-img="illustrations/auth-cover-login-mask-dark.html"
            />
          </div>

          <div className="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg position-relative py-sm-12 px-12 py-6">
            <div className="w-px-400 mx-auto pt-5 pt-lg-0">
              <h4 className="mb-1">Welcome to Bharat eClinics! 👋</h4>
              <p className="mb-5">Please sign-in to your account</p>
              <form
                id="formAuthentication"
                className="mb-5"
                onSubmit={handleSubmit}
              >
                <div className="form-floating form-floating-outline mb-5">
                  <input
                    type="text"
                    className="form-control"
                    id="identifier"
                    name="identifier"
                    placeholder="Enter your phone or username"
                    value={identifier}
                    onChange={(e) => setIdentifier(e.target.value)}
                    autoFocus
                  />
                  <label htmlFor="identifier">Phone or Username</label>
                </div>
                <div className="mb-5">
                  <div className="form-password-toggle">
                    <div className="input-group input-group-merge">
                      <div className="form-floating form-floating-outline">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          className="form-control"
                          name="password"
                          placeholder="············"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          aria-describedby="password"
                        />
                        <label htmlFor="password">Password</label>
                      </div>
                      <span
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        className="input-group-text cursor-pointer"
                      >
                        <i
                          className={
                            showPassword ? "ri-eye-line" : "ri-eye-off-line"
                          }
                        />
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div className="mb-5 d-flex justify-content-between mt-5">
                                <div className="form-check mt-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="remember-me"
                                        checked={rememberMe}
                                        onChange={() => setRememberMe(!rememberMe)}
                                    />
                                    <label className="form-check-label" htmlFor="remember-me">
                                        Remember Me
                                    </label>
                                </div>
                                <a href="auth-forgot-password-cover.html" className="float-end mb-1 mt-2">
                                    <span>Forgot Password?</span>
                                </a>
                            </div> */}
                {loading ? (
                  <button
                    className="btn btn-primary w-100"
                    type="button"
                    disabled
                  >
                    <span
                      className="spinner-border me-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Signing In
                  </button>
                ) : (
                  <button className="btn btn-primary d-grid w-100">
                    Sign in
                  </button>
                )}
              </form>
              {/* <p className="text-center">
                            <span>New on our platform?</span>
                            <Link to="/register">
                                <span> Create an account</span>
                            </Link>
                        </p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
