import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../components/utils/loggedin';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import fetchCurrentLocation from '../../components/utils/fetchlocation';
import CardsChartData from './cardsChartData';

export default function SuperAdminDashboard() {
    const { token } = useAuth()
    const [adminData, setAdminData] = useState({
        username: '',
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        is_phone_verified: false,
        phone_otp: '',
        phone_otp_expiry: '',
        admin_type: '',
        verified: false,
        created_at: null
    });
    const [doctorCount, setDoctorCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [patientsCount, setPatientsCount] = useState(0);
    const [salesRepCount, setSalesRepCount] = useState(0);
    const [clinicCount, setClinicCount] = useState(0);
    const [labsCount, setLabsCount] = useState(0);
    const [doctorAppointment, setDoctorAppointment] = useState(0);
    const [cardCount, setCardCount] = useState(0);
    const [activatedCards, setActivatedCards] = useState(0);
    const [notActivatedCards, setNotActivatedCards] = useState(0);
    const [scheduledAppointments, setScheduledAppointments] = useState(0);


    useEffect(() => {
        const fetchAdminData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/getadmin`, {
                    headers: {
                        "Signature": process.env.REACT_APP_API_SIGNATURE,
                        "Authorization": `Bearer ${token}`
                    }
                });
                const data = response.data;
                setAdminData({
                    username: data.username,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    phone: data.phone,
                    email: data.email,
                    address: data.address,
                    city: data.city,
                    state: data.state,
                    zip: data.zip,
                    is_phone_verified: data.is_phone_verified,
                    phone_otp: data.phone_otp,
                    phone_otp_expiry: data.phone_otp_expiry,
                    admin_type: data.admin_type,
                    verified: data.verified,
                    created_at: data.created_at
                });
            } catch (error) {
                console.error('There was an error fetching the admin data!', error);
            }
        };

        fetchAdminData();
    }, []);

    // get all doctor through this api
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/doctor/getalldoctors`, {
            headers: {
                "Signature": process.env.REACT_APP_API_SIGNATURE
            }
        })
            .then(response => {
                setDoctorCount(response.data.doctors.length);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching doctors:', error);
                setLoading(false);
            });
    }, []);

    // here is the api for  patient
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/user/getallusers`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Signature: process.env.REACT_APP_API_SIGNATURE,
                },
            })
            .then((response) => {
                // Count the  number of patients
                setPatientsCount(response.data.data.length);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching users:', error);
                setLoading(false);
            });
    }, [token]);

    //  sales api here

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/salesrep/getallsalesreps`, {
            headers: {
                Authorization: `Bearer ${token}`,
                Signature: process.env.REACT_APP_API_SIGNATURE,
            },
        })
            .then(response => {
                // Assuming response.data is an array of all sales representatives
                setSalesRepCount(response.data.length);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching sales reps:', error);
                setLoading(false);
            });
    }, [token]);

    // total clinic api here
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/clinic/getallclinics`, {
            headers: {
                Authorization: `Bearer ${token}`,
                Signature: process.env.REACT_APP_API_SIGNATURE,
            },
        })
            .then(response => {
                // Assuming response.data is an array of all sales representatives
                setClinicCount(response.data.length);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching sales reps:', error);
                setLoading(false);
            });
    }, [token]);
    // total labs api here 
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/labrep/getalllabreps`, {
            headers: {
                Authorization: `Bearer ${token}`,
                Signature: process.env.REACT_APP_API_SIGNATURE,
            },
        })
            .then(response => {
                // Assuming response.data is an array of all sales representatives
                setLabsCount(response.data.length);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching sales reps:', error);
                setLoading(false);
            });
    }, [token]);
    // total appointment by doctor
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/appointments/doctor`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Signature: process.env.REACT_APP_API_SIGNATURE,
                },
            })
            .then((response) => {
                const appointments = response.data;

                // Filter scheduled appointments
                const scheduledCount = appointments.filter(appointment => appointment.status === 'scheduled').length;

                setScheduledAppointments(scheduledCount);
                setDoctorAppointment(appointments.length)
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching appointments:', error);
                setLoading(false);
            });
    }, [token]);

    // total smarthealth card api here
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/healthcard/getallcards`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Signature: process.env.REACT_APP_API_SIGNATURE,
                },
            })
            .then((response) => {
                const cards = response.data;

                // Total cards
                setCardCount(cards.length);

                // Count activated and not activated cards
                const activatedCount = cards.filter(card => card.activated).length;
                const notActivatedCount = cards.length - activatedCount;

                setActivatedCards(activatedCount);
                setNotActivatedCards(notActivatedCount);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching health cards:', error);
                setLoading(false);
            });
        abc();
    }, [token]);

    async function abc() {
        const loc = await fetchCurrentLocation()

    }

    const navigate = useNavigate();


    return (

        <>
            <Helmet>
                <title>Dashboard Page</title>
            </Helmet>
            <div className="row g-6 ">
                {/* Total Profit */}
                <div className="col-sm-6 col-lg-3">
                    <div className="card card-border-shadow-primary">
                        <div
                            className="card-body"
                            onClick={() => navigate("/admins/doctors")}
                        >
                            <div className="d-flex align-items-center mb-2">
                                <div className="avatar me-4">
                                    <span className="avatar-initial rounded-3 bg-label-success">
                                        <i className="menu-icon tf-icons ri-stethoscope-line"></i>
                                    </span>
                                </div>
                                <h4 className="mb-0">
                                    {loading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        doctorCount
                                    )}
                                </h4>
                            </div>
                            <h6 className="mb-0 fw-normal">Doctors</h6>

                        </div>
                    </div>
                </div>


                {/* Total Patient */}
                <div className="col-sm-6 col-lg-3">
                    <div className="card card-border-shadow-primary">
                        <div
                            className="card-body"
                            onClick={() => navigate("/admins/patients")}
                        >
                            <div className="d-flex align-items-center mb-2">
                                <div className="avatar me-4">
                                    <span className="avatar-initial rounded-3 bg-label-success">
                                        <i className="menu-icon tf-icons ri-user-line"></i>
                                    </span>
                                </div>
                                <h4 className="mb-0">
                                    {loading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        patientsCount
                                    )}
                                </h4>
                            </div>
                            <h6 className="mb-0 fw-normal">Patients</h6>
                        </div>
                    </div>
                </div>


                {/* Sales */}
                <div className="col-sm-6 col-lg-3">
                    <div className="card card-border-shadow-primary h-100">
                        <div
                            className="card-body"
                            onClick={() => navigate("/admins/salesreps")}
                        >
                            <div className="d-flex align-items-center mb-2">
                                <div className="avatar me-4">
                                    <span className="avatar-initial rounded-3 bg-label-success">
                                        <i className="menu-icon tf-icons ri-line-chart-line"></i>
                                    </span>
                                </div>
                                <h4 className="mb-0">
                                    {loading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        salesRepCount
                                    )}
                                </h4>
                            </div>
                            <h6 className="mb-0 fw-normal">Sales Reps</h6>
                        </div>
                    </div>
                </div>


                {/* Clinic */}
                <div className="col-sm-6 col-lg-3">
                    <div
                        className="card card-border-shadow-primary h-100"
                        onClick={() => navigate("/admins/clinics")}
                    >
                        <div className="card-body">
                            <div className="d-flex align-items-center mb-2">
                                <div className="avatar me-4">
                                    <span className="avatar-initial rounded-3 bg-label-success">
                                        <i className="menu-icon tf-icons ri-hospital-line"></i>
                                    </span>
                                </div>
                                <h4 className="mb-0">
                                    {loading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        clinicCount
                                    )}
                                </h4>
                            </div>
                            <h6 className="mb-0 fw-normal">Clinics</h6>
                        </div>
                    </div>
                </div>


                {/* Labs */}
                <div className="col-sm-6 col-lg-3">
                    <div
                        className="card card-border-shadow-primary h-100"

                    >
                        <div className="card-body">
                            <div className="d-flex align-items-center mb-2">
                                <div className="avatar me-4">
                                    <span className="avatar-initial rounded-3 bg-label-success">
                                        <i className="ri-handbag-line ri-24px"></i>
                                    </span>
                                </div>
                                <h4 className="mb-0">
                                    {loading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        labsCount
                                    )}
                                </h4>
                            </div>
                            <h6 className="mb-0 fw-normal">Labs</h6>
                        </div>
                    </div>
                </div>


                {/* Doctor Appointment */}
                <div className="col-sm-6 col-lg-3">
                    <div
                        className="card card-border-shadow-primary h-100"

                    >
                        <div className="card-body">
                            <div className="d-flex align-items-center mb-2">
                                <div className="avatar me-4">
                                    <span className="avatar-initial rounded-3 bg-label-success">
                                        <i className="ri-handbag-line ri-24px"></i>
                                    </span>
                                </div>
                                <h4 className="mb-0">
                                    {loading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        doctorAppointment
                                    )}
                                </h4>
                            </div>
                            <h6 className="mb-0 fw-normal">Appointments</h6>
                        </div>
                    </div>
                </div>


                {/* Upcoming Appointments */}
                <div className="col-sm-6 col-lg-3">
                    <div
                        className="card card-border-shadow-primary h-100"

                    >
                        <div className="card-body">
                            <div className="d-flex align-items-center mb-2">
                                <div className="avatar me-4">
                                    <span className="avatar-initial rounded-3 bg-label-success">
                                        <i className="ri-handbag-line ri-24px"></i>
                                    </span>
                                </div>
                                <h4 className="mb-0">
                                    {loading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        scheduledAppointments
                                    )}
                                </h4>
                            </div>
                            <h6 className="mb-0 fw-normal">Upcoming Appointments</h6>
                        </div>
                    </div>
                </div>


                {/* Activated Cards */}
                <div className="col-sm-6 col-lg-3">
                    <div
                        className="card card-border-shadow-primary h-100"

                    >
                        <div className="card-body">
                            <div className="d-flex align-items-center mb-2">
                                <div className="avatar me-4">
                                    <span className="avatar-initial rounded-3 bg-label-success">
                                        <i className="ri-handbag-line ri-24px"></i>
                                    </span>
                                </div>
                                <h4 className="mb-0">
                                    {loading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        activatedCards
                                    )}
                                </h4>
                            </div>
                            <h6 className="mb-0 fw-normal">Activated Cards</h6>
                        </div>
                    </div>
                </div>


                {/* Not Activated Cards */}
                <div className="col-sm-6 col-lg-3">
                    <div
                        className="card card-border-shadow-primary h-100"

                    >
                        <div className="card-body">
                            <div className="d-flex align-items-center mb-2">
                                <div className="avatar me-4">
                                    <span className="avatar-initial rounded-3 bg-label-success">
                                        <i className="ri-handbag-line ri-24px"></i>
                                    </span>
                                </div>
                                <h4 className="mb-0">
                                    {loading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        notActivatedCards
                                    )}
                                </h4>
                            </div>
                            <h6 className="mb-0 fw-normal">Not Activated Cards</h6>
                        </div>
                    </div>
                </div>



            </div>
            <CardsChartData cardCount={cardCount} activatedCards={activatedCards} notActivatedCards={notActivatedCards} />



        </>

    )
}
