import axios from "axios";
import { toast } from "react-toastify";

const fetchCurrentLocation = async () => {
    navigator.geolocation.getCurrentPosition(
        async (position) => {
            const { latitude, longitude } = position.coords;

            try {
                const response = await axios.get(
                    `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
                );
                const { data } = response;
                return data;
            } catch (error) {
                console.error("Error fetching location data:", error);
                return error;
            }
        },
        (error) => {
            toast.error("Geolocation error:", error);
        }
    );
};

export default fetchCurrentLocation;
