import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function AllClinic() {
  const [clinics, setClinics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [clinicsPerPage, setClinicsPerPage] = useState(10);
  const [searchName, setSearchName] = useState("");
  const [searchUsername, setSearchUsername] = useState("");
  const [searchPhone, setSearchPhone] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [filterOnboarded, setFilterOnboarded] = useState("all");
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/clinic/getallclinics`, {
        headers: {
          Signature: process.env.REACT_APP_API_SIGNATURE,
        },
      })
      .then((response) => {
        setClinics(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching clinics:", error);
        setLoading(false);
      });
  }, []);

  function ViewClinic(clinicId) {
    navigate("/admins/clinics/view", { state: { clinicId } });
  }

  // Filter and search logic
  const filteredClinics = clinics.filter((clinic) => {
    const matchesName = clinic.first_name.toLowerCase().includes(searchName.toLowerCase()) || clinic.last_name.toLowerCase().includes(searchName.toLowerCase());
    const matchesUsername = clinic.username.toLowerCase().includes(searchUsername.toLowerCase());
    const matchesPhone = clinic.phone.includes(searchPhone);
    const matchesStatus = filterStatus === "all" || (filterStatus === "verified" && clinic.verified) || (filterStatus === "not_verified" && !clinic.verified);
    const matchesOnboarded = filterOnboarded === "all" || (filterOnboarded === "onboarded" && clinic.onboarded) || (filterOnboarded === "not_onboarded" && !clinic.onboarded);

    return matchesName && matchesUsername && matchesPhone && matchesStatus && matchesOnboarded;
  });

  // Calculate total pages
  const totalPages = Math.ceil(filteredClinics.length / clinicsPerPage);

  // Get current clinics based on the current page
  const indexOfLastClinic = currentPage * clinicsPerPage;
  const indexOfFirstClinic = indexOfLastClinic - clinicsPerPage;
  const currentClinics = filteredClinics.slice(indexOfFirstClinic, indexOfLastClinic);

  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  function handleItemsPerPageChange(e) {
    setClinicsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page on items per page change
  }

  return (
    <>
      <Helmet>
        <title>Manage Clinics Page</title>
      </Helmet>
      <div className="row g-6 mb-6">
        <div className="col-sm-6 col-xl-3">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <div className="me-1">
                  <p className="text-heading mb-1">Total Clinics</p>
                  <div className="d-flex align-items-center">
                    <h4 className="mb-1 me-2">{clinics.length}</h4>
                  </div>
                </div>
                <div className="avatar">
                  <div className="avatar-initial bg-label-primary rounded-3">
                    <div className="ri-group-line ri-26px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-3">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <div className="me-1">
                  <p className="text-heading mb-1">Pending Verification</p>
                  <div className="d-flex align-items-center">
                    <h4 className="mb-1 me-1">
                      {clinics.filter((clinic) => !clinic.verified).length}
                    </h4>
                  </div>
                </div>
                <div className="avatar">
                  <div className="avatar-initial bg-label-danger rounded-3">
                    <div className="ri-user-add-line ri-26px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-3">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <div className="me-1">
                  <p className="text-heading mb-1">Active Clinics</p>
                  <div className="d-flex align-items-center">
                    <h4 className="mb-1 me-1">
                      {clinics.filter((clinic) => clinic.onboarded).length}
                    </h4>
                  </div>
                </div>
                <div className="avatar">
                  <div className="avatar-initial bg-label-success rounded-3">
                    <div className="ri-user-follow-line ri-26px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-3">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <div className="me-1">
                  <p className="text-heading mb-1">Pending Onboarding</p>
                  <div className="d-flex align-items-center">
                    <h4 className="mb-1 me-1">
                      {clinics.filter((clinic) => !clinic.onboarded).length}
                    </h4>
                  </div>
                </div>
                <div className="avatar">
                  <div className="avatar-initial bg-label-warning rounded-3">
                    <div className="ri-user-search-line ri-26px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header border-bottom">
          <h5 className="card-title mb-0">Clinics</h5>

        </div>
        <div className="card-datatable table-responsive">
          <table className="table">
            <thead>
              <tr>
                <td colSpan="7">
                  <select
                    className="form-select form-select-sm"
                    value={clinicsPerPage}
                    onChange={handleItemsPerPageChange}
                    style={{ width: "150px" }}
                  >
                    <option value={5}>5 per page</option>
                    <option value={10}>10 per page</option>
                    <option value={15}>15 per page</option>
                    <option value={20}>20 per page</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th style={{ width: "5%" }}>S.No</th>
                <th style={{ width: "20%" }}>Username</th>
                <th style={{ width: "20%" }}>Name</th>
                <th style={{ width: "15%" }}>Phone</th>
                <th style={{ width: "15%" }}>Status</th>
                <th style={{ width: "15%" }}>Onboarded</th>
                <th style={{ width: "15%" }}>Actions</th>
              </tr>
              <tr>
                <td></td>
                <td>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Search by username"
                    value={searchUsername}
                    onChange={(e) => setSearchUsername(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Search by name"
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Search by phone"
                    value={searchPhone}
                    onChange={(e) => setSearchPhone(e.target.value)}
                  />
                </td>
                <td>
                  <select
                    className="form-select form-select-sm"
                    value={filterStatus}
                    onChange={(e) => setFilterStatus(e.target.value)}
                  >
                    <option value="all">All Status</option>
                    <option value="verified">Verified</option>
                    <option value="not_verified">Not Verified</option>
                  </select>
                </td>
                <td>
                  <select
                    className="form-select form-select-sm"
                    value={filterOnboarded}
                    onChange={(e) => setFilterOnboarded(e.target.value)}
                  >
                    <option value="all">All Onboarded</option>
                    <option value="onboarded">Onboarded</option>
                    <option value="not_onboarded">Not Onboarded</option>
                  </select>
                </td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="7">Loading...</td>
                </tr>
              ) : currentClinics.length === 0 ? (
                <tr>
                  <td colSpan="7" className="text-center text-muted">
                    No data found
                  </td>
                </tr>
              ) : (
                currentClinics.map((clinic, index) => (
                  <tr key={index}>
                    <td>{indexOfFirstClinic + index + 1}</td>
                    <td>
                      {clinic.username === "" ? "BHCLXXXX" : clinic.username}
                    </td>
                    <td>
                      {clinic.first_name} {clinic.last_name}
                    </td>
                    <td>{clinic.phone}</td>
                    <td
                      className={
                        clinic.verified ? "text-success" : "text-danger"
                      }
                    >
                      {clinic.verified ? (
                        <span className="badge bg-label-success rounded-pill">
                          Verified
                        </span>
                      ) : (
                        <span className="badge bg-label-danger rounded-pill">
                          Not Verified
                        </span>
                      )}
                    </td>
                    <td
                      className={
                        clinic.onboarded ? "text-success" : "text-warning"
                      }
                    >
                      {clinic.onboarded ? (
                        <span className="badge bg-label-success rounded-pill">
                          Onboarded
                        </span>
                      ) : (
                        <span className="badge bg-label-warning rounded-pill">
                          Not Onboarded
                        </span>
                      )}
                    </td>
                    <td>
                      <button
                        className="btn"
                        onClick={() => ViewClinic(clinic.id)}
                      >
                        <i className="ri-eye-line"></i>
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <nav
          className="flex justify-content-end mt-5 mx-2"
          aria-label="Page navigation"
        >
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange(1)}
              >
                <i className="tf-icon ri-skip-back-mini-line ri-20px" />
              </button>
            </li>
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <i className="tf-icon ri-arrow-left-s-line ri-20px" />
              </button>
            </li>
            {[...Array(totalPages)].map((_, index) => (
              <li
                key={index + 1}
                className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              </li>
            ))}
            <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <i className="tf-icon ri-arrow-right-s-line ri-20px" />
              </button>
            </li>
            <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange(totalPages)}
              >
                <i className="tf-icon ri-skip-forward-mini-line ri-20px" />
              </button>
            </li>
          </ul>
        </nav>

      </div>
    </>
  );
}
