import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../../../components/utils/loggedin';
import { toast } from 'react-toastify';

export default function Account({ clinic, fetchClinicData }) {
    const { token } = useAuth();
    const [otp, setOtp] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address: "",
        state: "",
        city: "",
        zip: "",
    });
    const [isOnboarded, setIsOnboarded] = useState(false);
    const [isVerified, setIsVerified] = useState(false);

    useEffect(() => {
        if (clinic) {
            setFormData({
                first_name: clinic.first_name || "",
                last_name: clinic.last_name || "",
                email: clinic.email || "",
                phone: clinic.phone || "",
                address: clinic.address || "",
                state: clinic.state || "",
                city: clinic.city || "",
                zip: clinic.zip || "",
            });
            setIsOnboarded(clinic.onboarded || false);
            setIsVerified(clinic.is_phone_verified || false);
        }
    }, [clinic]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const updateClinic = async () => {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/clinic/updateclinic`,
                formData,
                {
                    params: {
                        id: clinic.id
                    },
                    headers: {
                        Signature: process.env.REACT_APP_API_SIGNATURE,
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success(response.data.message || "Update successful!");
            document.getElementById("offcanvasEditClinic").classList.remove("show");
            document.body.classList.remove("modal-open");
            document.querySelector(".modal-backdrop")?.remove();
            fetchClinicData();

        } catch (error) {
            toast.error(
                error.response?.data?.message || "Failed to update clinic data."
            );
        }
    };

    const onboardClinic = async () => {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/clinic/onboardclinic/${clinic.id}`, {},

                {
                    headers: {
                        Signature: process.env.REACT_APP_API_SIGNATURE,
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success(response.data.message || "Clinic onboarded successfully!");
            setIsOnboarded(true);
            fetchClinicData();

        } catch (error) {
            toast.error(
                error.response?.data?.message || "Failed to onboard clinic."
            );
        }
    };

    // otp enter 
    const handleChangeOtp = (e, index) => {
        const value = e.target.value;
        if (isNaN(value)) return; // Prevent non-numeric input

        const otpArray = otp.split(""); // Ensure otp is a string
        otpArray[index] = value;

        // Combine the array back into a string
        setOtp(otpArray.join(""));

        // Focus on next input
        if (e.target.nextSibling) {
            e.target.nextSibling.focus();
        }
    };

    // verify otp
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/clinic/verifyotp`,
                { phone: clinic.phone, otp },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Signature: process.env.REACT_APP_API_SIGNATURE,
                    },
                }
            );
            console.log(response.data);
            toast.success(response.data.message);
            // Handle success (e.g., show a success message, redirect, etc.)
        } catch (error) {
            console.error(error);
            setErrorMessage("Invalid OTP or OTP expired");
            // Handle error (e.g., show an error message)
        }
    };

    // initiate verification
    const initiateVerification = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/clinic/initiateclinicverification`,
                {
                    phone: clinic.phone,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Signature: process.env.REACT_APP_API_SIGNATURE,
                    },
                }
            );
            if (response.status === 200) {
                toast.success(response.data.message);
                // setResendTimer(10); // Set timer for 30 seconds
                // setResendDisabled(true); // Disable resend button
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };



    const verifyClinic = async (e) => {
        e.preventDefault(); // Prevent the form from submitting and refreshing the page
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/clinic/verifyclinic`,
                { phone: formData.phone }, // Adjust this according to your actual API requirements
                {
                    headers: {
                        Signature: process.env.REACT_APP_API_SIGNATURE,
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast.success(response.data.message || "Clinic verified successfully!");
            setIsVerified(true); // Update state after successful verification
            fetchClinicData(); // Refresh the clinic data to reflect the updated status
        } catch (error) {
            toast.error(error.response?.data?.message || "Failed to verify clinic.");
        }
    };

    return (
        <>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Clinic Details</h5>
                    <div className="d-flex gap-2">
                        <button
                            className="btn btn-label-primary"
                            data-bs-target="#offcanvasEditClinic"
                            data-bs-toggle="offcanvas"
                        >
                            <i className="ri-pencil-line me-2"></i>
                            Edit
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={onboardClinic}
                            disabled={isOnboarded}
                        >
                            {isOnboarded ? "Onboarded" : "Onboard Clinic"}
                        </button>
                        {clinic.verified ? (
                            <button
                                data-bs-toggle="modal"
                                data-bs-target="#unverify"
                                className="btn btn-outline-danger suspend-user"
                            >
                                Suspend
                            </button>
                        ) : (
                            <button
                                data-bs-toggle="modal"
                                data-bs-target="#verifyOTP"
                                className="btn btn-outline-success suspend-user"
                                onClick={() => initiateVerification()}
                            >
                                Verify
                            </button>
                        )}
                    </div>

                </div>
                <div className="table-responsive text-nowrap">
                    <table className="table">
                        <tbody className="table-border-bottom-0">
                            <tr>
                                <td><span className="fw-medium">Name</span></td>
                                <td>{clinic.first_name + " " + clinic.last_name}</td>
                            </tr>
                            <tr>
                                <td><span className="fw-medium">Email</span></td>
                                <td>{clinic.email}</td>
                            </tr>
                            <tr>
                                <td><span className="fw-medium">Phone</span></td>
                                <td>{clinic.phone}</td>
                            </tr>
                            <tr>
                                <td><span className="fw-medium">Address</span></td>
                                <td>{`${clinic.address}, ${clinic.city}, ${clinic.state}, ${clinic.zip}`}</td>
                            </tr>
                            <tr>
                                <td><span className="fw-medium">Joined On</span></td>
                                <td>{new Date(clinic.created_at).toLocaleDateString()}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Edit Clinic Modal */}
            <div
                className="offcanvas offcanvas-end"
                tabIndex={-1}
                id="offcanvasEditClinic"
                aria-labelledby="offcanvasEditClinicLabel"
                style={{ width: '600px' }}
            >
                <div className="offcanvas-header border-bottom">
                    <h5 id="offcanvasEditClinicLabel" className="offcanvas-title">
                        Edit Clinic
                    </h5>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    />
                </div>
                <div className="offcanvas-body mx-0 flex-grow-0 h-100">
                    <form
                        className="add-new-user pt-0"
                        id="editClinic"
                        onSubmit={(e) => {
                            e.preventDefault();
                            updateClinic();
                        }}
                    >
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                id="edit-clinic-firstname"
                                className="form-control"
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleChange}
                            />
                            <label htmlFor="edit-clinic-firstname">First Name</label>
                        </div>
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                id="edit-clinic-lastname"
                                className="form-control"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleChange}
                            />
                            <label htmlFor="edit-clinic-lastname">Last Name</label>
                        </div>
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                id="edit-clinic-email"
                                className="form-control"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                            <label htmlFor="edit-clinic-email">Email</label>
                        </div>
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                id="edit-clinic-phone"
                                className="form-control"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                            />
                            <label htmlFor="edit-clinic-phone">Phone</label>
                        </div>
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                id="edit-clinic-address"
                                className="form-control"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                            />
                            <label htmlFor="edit-clinic-address">Address</label>
                        </div>
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                id="edit-clinic-city"
                                className="form-control"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                            />
                            <label htmlFor="edit-clinic-city">City</label>
                        </div>
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                id="edit-clinic-state"
                                className="form-control"
                                name="state"
                                value={formData.state}
                                onChange={handleChange}
                            />
                            <label htmlFor="edit-clinic-state">State</label>
                        </div>
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                id="edit-clinic-zip"
                                className="form-control"
                                name="zip"
                                value={formData.zip}
                                onChange={handleChange}
                            />
                            <label htmlFor="edit-clinic-zip">Zip</label>
                        </div>
                        <div className="d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary me-2">
                                Save changes
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="offcanvas"
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {/* verify model  */}
            <div
                className="modal fade"
                id="verifyOTP"
                tabIndex={-1}
                aria-hidden="true"
            >
                <div className="modal-dialog modal-simple modal-enable-otp modal-dialog-centered">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                        <div className="card-body mt-1">
                            <h4 className="mb-1"> Verification of the user 💬</h4>
                            <p className="text-start mb-5">
                                We sent a verification code to your mobile. Enter the code from
                                the mobile in the field below.
                                <span className="d-block mt-1 h6">{clinic.phone}</span>
                            </p>
                            <p className="mb-0">Type your 6 digit security code</p>
                            <form id="twoStepsForm" onSubmit={verifyClinic}>

                                <div className="mb-3">
                                    <div className="auth-input-wrapper d-flex align-items-center justify-content-between numeral-mask-wrapper">
                                        {[...Array(6)].map((_, index) => (
                                            <input
                                                key={index}
                                                type="tel"
                                                className="form-control auth-input text-center numeral-mask h-px-50 mx-sm-1 my-2"
                                                maxLength={1}
                                                value={otp[index] || ""}
                                                onChange={(e) => handleChangeOtp(e, index)}
                                                onFocus={(e) => e.target.select()}
                                                autoFocus={index === 0}
                                            />
                                        ))}
                                    </div>
                                    {/* Hidden field not necessary when using string */}
                                </div>
                                <button
                                    className="btn btn-primary d-grid w-100 mb-5"
                                    type="submit"
                                >
                                    Verify my account
                                </button>
                                <div className="text-center">
                                    Didn't get the code?

                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* unverify clinic  */}
            <div
                className="modal fade"
                id="unverify"
                tabIndex={-1}
                aria-hidden="true"
            >
                <div className="modal-dialog modal-simple modal-enable-otp modal-dialog-centered">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                        <div className="modal-body p-0">
                            <div className="text-center mb-6">
                                <h2 className="mb-2">Are you sure !</h2>
                                {/* <p>Verify Your Mobile Number for SMS</p> */}
                            </div>
                            <p className="mb-5 text-center">
                                Are you sure you want to unverify this admin account?
                                <br /> Please confirm your decision to proceed with the
                                unverification process.
                            </p>

                            <form
                                id="enableOTPForm"
                                className="row g-5"
                                onsubmit="return false"
                            >
                                {/* <div className="col-12">
                  <div className="input-group input-group-merge">
                    <span className="input-group-text">US (+1)</span>
                    <div className="form-floating form-floating-outline">
                      <input
                        type="text"
                        id="modalEnableOTPPhone"
                        name="modalEnableOTPPhone"
                        className="form-control phone-number-otp-mask"
                        placeholder="202 555 0111"
                      />
                      <label htmlFor="modalEnableOTPPhone">Phone Number</label>
                    </div>
                  </div>
                </div> */}
                                <div className="col-12 d-flex flex-wrap justify-content-center gap-4 row-gap-4">
                                    <button
                                        type="submit"
                                        className="btn btn-success"
                                    // onClick={(e) => (e)}
                                    >
                                        Unverify
                                    </button>
                                    <button
                                        type="reset"
                                        className="btn btn-outline-danger"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
