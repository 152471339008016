import React from 'react'

export default function Notification() {
    return (
        <>
            <div className="card mb-6">
                {/* Notifications */}
                <h5 className="card-header border-bottom mb-0">Notifications</h5>
                <div className="card-body py-4">
                    <span className="h6 mb-0">
                        You will receive notification for the below selected items.
                    </span>
                </div>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="text-nowrap">Type</th>
                                <th className="text-nowrap text-center">Email</th>
                                <th className="text-nowrap text-center">Browser</th>
                                <th className="text-nowrap text-center">App</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-nowrap text-heading">New for you</td>
                                <td>
                                    <div className="form-check mb-0 d-flex justify-content-center">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="defaultCheck1"
                                            defaultChecked=""
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-check mb-0 d-flex justify-content-center">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="defaultCheck2"
                                            defaultChecked=""
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-check mb-0 d-flex justify-content-center">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="defaultCheck3"
                                            defaultChecked=""
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-nowrap text-heading">Account activity</td>
                                <td>
                                    <div className="form-check mb-0 d-flex justify-content-center">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="defaultCheck4"
                                            defaultChecked=""
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-check mb-0 d-flex justify-content-center">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="defaultCheck5"
                                            defaultChecked=""
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-check mb-0 d-flex justify-content-center">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="defaultCheck6"
                                            defaultChecked=""
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-nowrap text-heading">
                                    A new browser used to sign in
                                </td>
                                <td>
                                    <div className="form-check mb-0 d-flex justify-content-center">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="defaultCheck7"
                                            defaultChecked=""
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-check mb-0 d-flex justify-content-center">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="defaultCheck8"
                                            defaultChecked=""
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-check mb-0 d-flex justify-content-center">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="defaultCheck9"
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-nowrap text-heading">A new device is linked</td>
                                <td>
                                    <div className="form-check mb-0 d-flex justify-content-center">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="defaultCheck10"
                                            defaultChecked=""
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-check mb-0 d-flex justify-content-center">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="defaultCheck11"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-check mb-0 d-flex justify-content-center">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="defaultCheck12"
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="card-body">
                    <button type="submit" className="btn btn-primary me-3">
                        Save changes
                    </button>
                    <button type="reset" className="btn btn-outline-secondary">
                        Discard
                    </button>
                </div>
                {/* /Notifications */}
            </div>
            {/* /Project table */}
        </>
    )
}
