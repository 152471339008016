import DeleteTestimonial from './delete_testimonial';
import EditTestimonial from './edit_testimonial'
import AddTestimonial from './add_testimonial'
import { useState, useEffect } from 'react';
import { useAuth } from '../../components/utils/loggedin';
import axios from 'axios';
export default function AllTestimonials() {
    const { token } = useAuth();
    const [selectedTestimonial, setSelectedTestimonial] = useState(null);
    const [testimonials, setTestimonials] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limit, setLimit] = useState(10);

    const fetchTestimonial = async (page = 1) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/testimonial/getall?page=${page}&limit=${limit}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Signature: `${process.env.REACT_APP_API_SIGNATURE}`,
                }
            });
            setTestimonials(res.data.testimonials);
            setCurrentPage(page);
            setTotalPages(res?.data?.pagination.pageSize);
        } catch (error) {
            console.error('Error fetching testimonials:', error);
        }
    }
    const renderPagination = () => {
        const pageNumbers = [];
        const maxPageButtons = 5;
        let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
        let endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

        if (endPage - startPage < maxPageButtons - 1) {
            startPage = Math.max(endPage - maxPageButtons + 1, 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(i)}>
                        {i}
                    </button>
                </li>
            );
        }

        const handlePageChange = (page) => {
            if (page < 1 || page > totalPages) return;
            fetchTestimonial(page);
        };

        return (
            <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                        <i className="tf-icon ri-arrow-left-s-line ri-20px" />
                    </button>
                </li>
                {startPage > 1 && (
                    <>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(1)}>1</button>
                        </li>
                        <li className="page-item disabled"><span className="page-link">...</span></li>
                    </>
                )}
                {pageNumbers}
                {endPage < totalPages && (
                    <>
                        <li className="page-item disabled"><span className="page-link">...</span></li>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
                        </li>
                    </>
                )}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                        <i className="tf-icon ri-arrow-right-s-line ri-20px" />
                    </button>
                </li>
            </ul>
        );
    };


    useEffect(() => {
        fetchTestimonial();
    }, [token, limit]);

    return (
        <div className="row g-6 mb-6">
            <div className="col-12">
                <div className="card">
                    <div className="d-flex flex-column flex-md-row justify-content-between align-items-center p-2 py-4 mt-3 mt-md-0">
                        <h5 className="card-title p-2 mb-2 mb-md-0">Testimonials</h5>
                        <button
                            data-bs-target="#addNewTestimonial"
                            data-bs-toggle="offcanvas"
                            className="btn btn-label-primary text-nowrap add-new-role"
                        >
                            <i className='ri-add-line me-2'></i> Add Testimonial
                        </button>
                        <AddTestimonial fetchTestimonial={fetchTestimonial} />
                    </div>


                    <div className="card-datatable table-responsive">

                        <table className="datatables-users table">
                            <thead>
                                <tr>
                                    <td colSpan="7">
                                        <select
                                            className="form-select form-select-sm"
                                            value={limit}
                                            onChange={(e) => setLimit(e.target.value)}

                                            style={{ width: "150px" }}
                                        >
                                            <option value={10}>10 per page</option>
                                            <option value={20}>20 per page</option>
                                            <option value={50}>50 per page</option>
                                            <option value={100}>100 per page</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Title</th>
                                    <th>Quote</th>
                                    <th>Image</th>
                                    <th className="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {testimonials.length > 0 ? (
                                    testimonials.map((testimonial, index) => (
                                        <tr key={testimonial.id}>
                                            <td>{index + 1}</td>
                                            <td>{testimonial.name}</td>
                                            <td>{testimonial.title}</td>
                                            <td>{testimonial.quote.split(' ').slice(0, 5).join(' ')}..</td>
                                            <td><img src={testimonial.image} alt={testimonial.name} width={50} height={50} /></td>
                                            <td className="text-center">
                                                <button
                                                    onClick={() => setSelectedTestimonial(testimonial)}
                                                    data-bs-target="#editTestimonial"
                                                    data-bs-toggle="offcanvas"
                                                    className="btn text-primary"
                                                >
                                                    <i className='ri-edit-line me-2'></i>
                                                </button>
                                                <button
                                                    onClick={() => setSelectedTestimonial(testimonial)}
                                                    data-bs-target="#deleteTestimonial"
                                                    data-bs-toggle="modal"
                                                    className="btn text-danger"
                                                >
                                                    <i className='ri-delete-bin-line me-2'></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6" className="text-center">No testimonials available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="row mt-3">
                            <nav aria-label="Page navigation">
                                {renderPagination()}
                            </nav>
                        </div>
                        <DeleteTestimonial fetchTestimonial={fetchTestimonial} testimonial={selectedTestimonial} />
                        <EditTestimonial fetchTestimonial={fetchTestimonial} testimonial={selectedTestimonial} />
                    </div>
                </div>
            </div>
        </div>
    );
}
