import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../../../components/utils/loggedin';
import { toast } from 'react-toastify';

export default function ViewSalesRepsAccount({ sales, fetchSalesRepData }) {
    const { token } = useAuth();
    const [otp, setOtp] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    // State for form data
    const [formdata, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address: "",
        state: "",
        city: "",
        zip: "",
    });

    useEffect(() => {
        if (sales) {
            setFormData({
                first_name: sales.first_name || "",
                last_name: sales.last_name || "",
                email: sales.email || "",
                phone: sales.phone || "",
                address: sales.address || "",
                state: sales.state || "",
                city: sales.city || "",
                zip: sales.zip || "",
            });
        }
    }, [sales]);

    const handleChange = (e) => {
        setFormData({ ...formdata, [e.target.name]: e.target.value });
    };

    // Update API for the sales representative
    const updateSalesRepre = async () => {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/salesrep/updatesalesrep`,
                formdata,
                {
                    params: {
                        id: sales.id
                    },
                    headers: {
                        Signature: process.env.REACT_APP_API_SIGNATURE,
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            // If the update is successful, log the success and display a success toast
            console.log("Update successful:", response.data);
            toast.success(response.data.message || "Update successful!");

            // Close the modal and refresh the sales rep data
            document.getElementById("offcanvasAddSalesRep").classList.remove("show");
            document.body.classList.remove("modal-open");
            document.querySelector(".modal-backdrop")?.remove();
            window.location.reload()

            // Fetch updated data
            fetchSalesRepData();

        } catch (error) {
            console.error("Error updating sales rep data:", error);

            // Display an error toast with a fallback message
            toast.error(
                error.response?.data?.message || "Failed to update sales representative data."
            );
        }
    };

    // onboarding sales

    const onboardSalesRep = async (salesRepId) => {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/salesrep/onboardsalesrep/${salesRepId}`, // Pass salesRepId directly in the URL
                {}, // Empty body since this is a PUT request with no body content
                {
                    headers: {
                        Signature: process.env.REACT_APP_API_SIGNATURE,
                        Authorization: `Bearer ${token}`, // Token for authorization
                    },
                }
            );

            // If the onboarding is successful, log the success and display a success toast
            console.log("Onboarding successful:", response.data);
            toast.success(response.data.message || "Sales Representative onboarded successfully!");

            // Optionally, you can close the modal or any UI elements associated with this action
            document.getElementById("offcanvasAddSalesRep").classList.remove("show");
            document.body.classList.remove("modal-open");
            document.querySelector(".modal-backdrop")?.remove();
            window.location.reload(); // Refresh the page to reflect the onboarded status

            // Fetch updated data
            fetchSalesRepData();

        } catch (error) {
            console.error("Error onboarding sales rep:", error);

            // Display an error toast with a fallback message
            toast.error(
                error.response?.data?.message || "Failed to onboard sales representative."
            );
        }
    };

    // otp fuction

    // verify otp
    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const response = await axios.post(
    //             `${process.env.REACT_APP_API_URL}/salesrep/verifyotp`,
    //             { phone: sales.phone, otp },
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                     Signature: process.env.REACT_APP_API_SIGNATURE,
    //                 },
    //             }
    //         );
    //         console.log(response.data);
    //         toast.success(response.data.message);
    //         // Handle success (e.g., show a success message, redirect, etc.)
    //     } catch (error) {
    //         console.error(error);
    //         setErrorMessage("Invalid OTP or OTP expired");
    //         // Handle error (e.g., show an error message)
    //     }
    // };

    // initiate verification





    return (
        <>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center flex-wrap gap-2">
                    <h5 className="mb-0">Details</h5>
                    <div className="d-flex flex-wrap justify-content-center gap-2 mt-2 mt-md-0">
                        <button
                            className="btn btn-label-primary"
                            data-bs-target="#offcanvasAddSalesRep"
                            data-bs-toggle="offcanvas"
                        >
                            <i className="ri-pencil-line me-2"></i>
                            Edit
                        </button>
                        <button
                            className={`btn btn-label-primary ${sales.onboarded ? 'disabled' : ''}`}
                            data-bs-toggle="modal"
                            data-bs-target="#onboardSalesRepModal"
                            disabled={sales.onboarded}
                        >
                            {sales.onboarded ? "Onboarded" : "Onboard SalesRep"}
                        </button>

                    </div>
                </div>

                <div className="table-responsive text-nowrap">
                    <table className="table">
                        <tbody className="table-border-bottom-0">
                            <tr>
                                <td>
                                    <span className="fw-medium">Username</span>
                                </td>
                                <td>{sales.username || "BHASXXXX"}</td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="fw-medium">Email</span>
                                </td>
                                <td>{sales.email}</td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="fw-medium">Status</span>
                                </td>
                                <td className={`${sales.verified ? "text-success" : "text-danger"}`}>
                                    {sales.verified ? "Verified" : "Not Verified"}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="fw-medium">Contact</span>
                                </td>
                                <td>{sales.phone}</td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="fw-medium">Address</span>
                                </td>
                                <td>
                                    {`${sales.address}, ${sales.state}, ${sales.city}, ${sales.zip}`}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="fw-medium">Joined On</span>
                                </td>
                                <td>{new Date(sales.created_at).toLocaleDateString()}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Edit Sales Rep Modal */}
            <div
                className="offcanvas offcanvas-end"
                tabIndex={-1}
                id="offcanvasAddSalesRep"
                aria-labelledby="offcanvasAddSalesRepLabel"
                style={{ width: '600px' }}
            >
                <div className="offcanvas-header border-bottom">
                    <h5 id="offcanvasAddSalesRepLabel" className="offcanvas-title">
                        Edit Sales Representative
                    </h5>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    />
                </div>
                <div className="offcanvas-body mx-0 flex-grow-0 h-100">
                    <form
                        className="add-new-user pt-0"
                        id="editSalesRep"
                        onSubmit={(e) => {
                            e.preventDefault();
                            updateSalesRepre();
                        }}
                    >
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                id="add-sales-rep-firstname"
                                className="form-control"
                                name="first_name"
                                value={formdata.first_name}
                                onChange={handleChange}
                            />
                            <label htmlFor="add-sales-rep-firstname">First Name</label>
                        </div>
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                id="add-sales-rep-lastname"
                                className="form-control"
                                name="last_name"
                                value={formdata.last_name}
                                onChange={handleChange}
                            />
                            <label htmlFor="add-sales-rep-lastname">Last Name</label>
                        </div>
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                id="add-sales-rep-email"
                                className="form-control"
                                name="email"
                                value={formdata.email}
                                onChange={handleChange}
                            />
                            <label htmlFor="add-sales-rep-email">Email</label>
                        </div>

                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                id="add-sales-rep-contact"
                                className="form-control"
                                name="phone"
                                value={formdata.phone}
                                onChange={handleChange}
                            />
                            <label htmlFor="add-sales-rep-contact">Contact</label>
                        </div>

                        <div className="form-floating form-floating-outline mb-5">
                            <textarea
                                id="add-sales-rep-address"
                                name="address"
                                className="form-control h-auto"
                                value={formdata.address}
                                onChange={handleChange}
                            />
                            <label htmlFor="add-sales-rep-address">Address</label>
                        </div>

                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                id="add-sales-rep-state"
                                name="state"
                                className="form-control h-auto"
                                value={formdata.state}
                                onChange={handleChange}
                            />
                            <label htmlFor="add-sales-rep-state">State</label>
                        </div>

                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                id="add-sales-rep-city"
                                name="city"
                                className="form-control h-auto"
                                value={formdata.city}
                                onChange={handleChange}
                            />
                            <label htmlFor="add-sales-rep-city">City</label>
                        </div>

                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                id="add-sales-rep-zip"
                                name="zip"
                                className="form-control h-auto"
                                value={formdata.zip}
                                onChange={handleChange}
                            />
                            <label htmlFor="add-sales-rep-zip">Zip</label>
                        </div>

                        <div className="col-12 text-center d-flex flex-wrap justify-content-center gap-4 row-gap-4">
                            <button
                                type="submit"
                                className="btn btn-success me-sm-3 me-1 data-submit"
                            >
                                Update
                            </button>
                            <button
                                type="reset"
                                className="btn btn-outline-secondary"
                                data-bs-dismiss="offcanvas"
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {/* onboarded Sales Rep Modal */}
            <div
                className="modal fade"
                id="onboardSalesRepModal"
                tabIndex={-1}
                aria-labelledby="onboardSalesRepModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="onboardSalesRepModalLabel">Onboard Sales Representative</h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            Are you sure you want to onboard this sales representative?
                        </div>
                        <div className="modal-footer d-flex flex-column flex-sm-row gap-2">
                            <button
                                type="button"
                                className="btn btn-secondary w-100 w-sm-auto"
                                data-bs-dismiss="modal"
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary w-100 w-sm-auto"
                                onClick={() => onboardSalesRep(sales.id)}
                                data-bs-dismiss="modal"
                            >
                                Yes, Onboard
                            </button>
                        </div>
                    </div>
                </div>
            </div>




        </>
    );
}
