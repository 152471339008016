import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function AllDoctors() {
    const [doctors, setDoctors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [doctorsPerPage, setDoctorsPerPage] = useState(5); // Initial value set to 5
    const [searchName, setSearchName] = useState('');
    const [searchUsername, setSearchUsername] = useState('');
    const [searchPhone, setSearchPhone] = useState('');
    const [searchStatus, setSearchStatus] = useState('');
    const [searchOnboarded, setSearchOnboarded] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/doctor/getalldoctors`, {
            headers: {
                "Signature": process.env.REACT_APP_API_SIGNATURE
            }
        })
            .then(response => {
                setDoctors(response.data.doctors);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching doctors:', error);
                setLoading(false);
            });
    }, []);

    function ViewDoctor(doctorId) {
        navigate('/admins/doctors/view', { state: { doctorId } });
    }

    const filteredDoctors = doctors.filter(doctor => {
        const nameMatch = (doctor.first_name || '').toLowerCase().includes(searchName.toLowerCase()) || (doctor.last_name || '').toLowerCase().includes(searchName.toLowerCase());
        const usernameMatch = (doctor.username || '').toLowerCase().includes(searchUsername.toLowerCase());
        const phoneMatch = (doctor.phone || '').includes(searchPhone);
        const statusMatch = searchStatus ? (doctor.verified ? 'Verified' : 'Not Verified') === searchStatus : true;
        const onboardedMatch = searchOnboarded ? (doctor.onboarded ? 'Onboarded' : 'Not Onboarded') === searchOnboarded : true;

        return nameMatch && usernameMatch && phoneMatch && statusMatch && onboardedMatch;
    });

    const totalPages = Math.ceil(filteredDoctors.length / doctorsPerPage);
    const indexOfLastDoctor = currentPage * doctorsPerPage;
    const indexOfFirstDoctor = indexOfLastDoctor - doctorsPerPage;
    const currentDoctors = filteredDoctors.slice(indexOfFirstDoctor, indexOfLastDoctor);

    function handlePageChange(pageNumber) {
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    }

    function handleItemsPerPageChange(event) {
        setDoctorsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1); // Reset to first page when items per page changes
    }

    return (
        <>
            <Helmet>
                <title>Manage Doctors Page</title>
            </Helmet>
            <div className="row g-6 mb-6">
                <div className="col-sm-6 col-xl-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div className="me-1">
                                    <p className="text-heading mb-1">Total Doctors</p>
                                    <div className="d-flex align-items-center">
                                        <h4 className="mb-1 me-2">{doctors.length}</h4>
                                    </div>
                                </div>
                                <div className="avatar">
                                    <div className="avatar-initial bg-label-primary rounded-3">
                                        <div className="ri-group-line ri-26px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div className="me-1">
                                    <p className="text-heading mb-1">Pending Verification</p>
                                    <div className="d-flex align-items-center">
                                        <h4 className="mb-1 me-1">{doctors.filter(doctor => !doctor.verified).length}</h4>
                                    </div>
                                </div>
                                <div className="avatar">
                                    <div className="avatar-initial bg-label-danger rounded-3">
                                        <div className="ri-user-add-line ri-26px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div className="me-1">
                                    <p className="text-heading mb-1">Active Doctors</p>
                                    <div className="d-flex align-items-center">
                                        <h4 className="mb-1 me-1">{doctors.filter(doctor => doctor.onboarded).length}</h4>
                                    </div>
                                </div>
                                <div className="avatar">
                                    <div className="avatar-initial bg-label-success rounded-3">
                                        <div className="ri-user-follow-line ri-26px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div className="me-1">
                                    <p className="text-heading mb-1">Pending Onboarding</p>
                                    <div className="d-flex align-items-center">
                                        <h4 className="mb-1 me-1">{doctors.filter(doctor => !doctor.onboarded).length}</h4>
                                    </div>
                                </div>
                                <div className="avatar">
                                    <div className="avatar-initial bg-label-warning rounded-3">
                                        <div className="ri-user-search-line ri-26px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header border-bottom">
                    <h5 className="card-title mb-0">Doctors</h5>

                </div>
                <div className="card-datatable table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <td colSpan="7">
                                    <select
                                        className="form-select form-select-sm"
                                        value={doctorsPerPage}
                                        onChange={handleItemsPerPageChange}
                                        style={{ width: "150px" }}
                                    >
                                        <option value={5}>5 per page</option>
                                        <option value={10}>10 per page</option>
                                        <option value={15}>15 per page</option>
                                        <option value={20}>20 per page</option>
                                        <option value={25}>25 per page</option>
                                        <option value={30}>30 per page</option>
                                        <option value={40}>40 per page</option>
                                        <option value={50}>50 per page</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th style={{ width: "5%" }}>S.No</th>
                                <th style={{ width: "20%" }}>Username</th>
                                <th style={{ width: "20%" }}>Name</th>
                                <th style={{ width: "15%" }}>Phone</th>
                                <th style={{ width: "15%" }}>Status</th>
                                <th style={{ width: "15%" }}>Onboarded</th>
                                <th style={{ width: "15%" }}>Actions</th>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Search by username"
                                        value={searchUsername}
                                        onChange={(e) => setSearchUsername(e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Search by name"
                                        value={searchName}
                                        onChange={(e) => setSearchName(e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Search by phone"
                                        value={searchPhone}
                                        onChange={(e) => setSearchPhone(e.target.value)}
                                    />
                                </td>
                                <td>
                                    <select
                                        className="form-select form-select-sm"
                                        value={searchStatus}
                                        onChange={(e) => setSearchStatus(e.target.value)}
                                    >
                                        <option value="">All Status</option>
                                        <option value="Verified">Verified</option>
                                        <option value="Not Verified">Not Verified</option>
                                    </select>
                                </td>
                                <td>
                                    <select
                                        className="form-select form-select-sm"
                                        value={searchOnboarded}
                                        onChange={(e) => setSearchOnboarded(e.target.value)}
                                    >
                                        <option value="">All Onboarded</option>
                                        <option value="Onboarded">Onboarded</option>
                                        <option value="Not Onboarded">Not Onboarded</option>
                                    </select>
                                </td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="7">Loading...</td>
                                </tr>
                            ) : currentDoctors.length === 0 ? (
                                <tr>
                                    <td colSpan="7" className="text-center">No data found</td>
                                </tr>
                            ) : (
                                currentDoctors.map((doctor, index) => (
                                    <tr key={doctor.id}>
                                        <td>{indexOfFirstDoctor + index + 1}</td>
                                        <td>{doctor.username}</td>
                                        <td>{doctor.first_name} {doctor.last_name}</td>
                                        <td>{doctor.phone}</td>
                                        <td className={doctor.verified ? "text-success" : "text-danger"}>
                                            {doctor.verified ? (
                                                <span className="badge bg-label-success rounded-pill">Verified</span>
                                            ) : (
                                                <span className="badge bg-label-danger rounded-pill">Not Verified</span>
                                            )}
                                        </td>
                                        <td className={doctor.onboarded ? "text-success" : "text-warning"}>
                                            {doctor.onboarded ? (
                                                <span className="badge bg-label-success rounded-pill">Onboarded</span>
                                            ) : (
                                                <span className="badge bg-label-warning rounded-pill">Not Onboarded</span>
                                            )}
                                        </td>
                                        <td>
                                            <button className='btn' onClick={() => ViewDoctor(doctor.id)}>
                                                <i className="ri-eye-line"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
                <nav className="flex justify-content-end mt-5 mx-2" aria-label="Page navigation">
                    <ul className="pagination">
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(1)}>
                                <i className="tf-icon ri-skip-back-mini-line ri-20px" />
                            </button>
                        </li>
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                                <i className="tf-icon ri-arrow-left-s-line ri-20px" />
                            </button>
                        </li>
                        {Array.from({ length: totalPages }, (_, index) => (
                            <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                    {index + 1}
                                </button>
                            </li>
                        ))}
                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                                <i className="tf-icon ri-arrow-right-s-line ri-20px" />
                            </button>
                        </li>
                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(totalPages)}>
                                <i className="tf-icon ri-skip-forward-mini-line ri-20px" />
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    );
}
