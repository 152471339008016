import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../../components/utils/loggedin"; // Assuming you have a custom hook for authentication

export default function UserAppointments({ user }) {
  const [appointments, setAppointments] = useState([]);
  // const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage, setItemsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token } = useAuth();
  const pages = 10;
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);


  const fetchAppointments = async (page = 1) => {
    if (!user || !user.id) {
      setError("User ID is not provided");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/appointments/user/${user.id}?page=${page}&limit=${limit}`,
        {
          headers: {
            Signature: process.env.REACT_APP_API_SIGNATURE,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Appointments fetched:", response.data); // Log API response
      setAppointments(response.data.data); // Assuming response.data is an array of appointments
      setCurrentPage(page);
      setTotalPages(response?.data?.pagination.totalPages);
    } catch (err) {
      console.error("Error fetching appointments:", err);
      setError("Error fetching appointments");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {

    fetchAppointments();
  }, [token, user.id, limit]); // Ensure this effect runs when the user changes

  // useEffect(() => {
  //   setFilteredAppointments(appointments);
  // }, [appointments]);

  // const indexOfLastAppointment = currentPage * itemsPerPage;
  // const indexOfFirstAppointment = indexOfLastAppointment - itemsPerPage;
  // const currentAppointments = filteredAppointments.slice(indexOfFirstAppointment, indexOfLastAppointment);
  // const totalPages = Math.ceil(filteredAppointments.length / itemsPerPage);

  const renderPagination = () => {
    const pageNumbers = [];
    const maxPageButtons = 5;
    let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
    let endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

    if (endPage - startPage < maxPageButtons - 1) {
      startPage = Math.max(endPage - maxPageButtons + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }

    const handlePageChange = (page) => {
      if (page < 1 || page > totalPages) return;
      fetchAppointments(page);
    };

    return (
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
            <i className="tf-icon ri-arrow-left-s-line ri-20px" />
          </button>
        </li>
        {startPage > 1 && (
          <>
            <li className="page-item">
              <button className="page-link" onClick={() => handlePageChange(1)}>1</button>
            </li>
            <li className="page-item disabled"><span className="page-link">...</span></li>
          </>
        )}
        {pageNumbers}
        {endPage < totalPages && (
          <>
            <li className="page-item disabled"><span className="page-link">...</span></li>
            <li className="page-item">
              <button className="page-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
            </li>
          </>
        )}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
            <i className="tf-icon ri-arrow-right-s-line ri-20px" />
          </button>
        </li>
      </ul>
    );
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

    return `${day}/${month}/${year}`;
  };

  // Calculate counts for scheduled and completed appointments


  return (
    <div className="card">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Appointments</h5>
      </div>

      {/* Items per page dropdown */}
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <td colSpan="7">
                <select
                  className="form-select form-select-sm"
                  value={limit}
                  onChange={(e) => setLimit(e.target.value)}
                  style={{ width: "150px" }}
                >
                  <option value={10}>10 per page</option>
                  <option value={20}>20 per page</option>
                  <option value={50}>50 per page</option>
                  <option value={100}>100 per page</option>
                </select>
              </td>
              {/* <tr>
                <th colSpan="7" className="text-center">
                  {filteredAppointments.length === 0
                    ? "No appointments found"
                    : `Scheduled: ${counts.scheduled}, Completed: ${counts.completed}`}
                </th>
              </tr> */}
            </tr>
            <tr>
              <th>S.No</th>
              <th>Doctor Name</th>
              <th>Status</th>
              <th>Date</th>
              <th>Arrival Time</th>
              <th>Departure Time</th>
              <th>Appointment Type</th>
            </tr>
          </thead>
          <tbody>
            {appointments.length === 0 ? (
              <tr>
                <td colSpan="7" className="text-center">No appointments found</td>
              </tr>
            ) : (
              appointments.map((appointment, index) => (
                <tr key={appointment.id}>
                  <td>{(currentPage - 1) * pages + index + 1}</td>
                  <td>
                    {appointment.Doctor ? (
                      `${appointment.Doctor.first_name} ${appointment.Doctor.last_name}`
                    ) : (
                      "Unknown Doctor"
                    )}
                  </td>
                  <td>
                    {appointment.status === "scheduled" ? (
                      <span className="badge bg-info rounded-pill">Scheduled</span>
                    ) : (
                      <span className="badge bg-danger rounded-pill">Rejected</span>
                    )}
                  </td>
                  <td>{formatDate(appointment.date)}</td>
                  <td>{appointment.arrival_time}</td>
                  <td>{appointment.departure_time}</td>
                  <td>{appointment.appointment_type}</td>
                </tr>
              ))
            )}
          </tbody>
          {/* Pagination */}
          <div className="row mt-3">
            <nav aria-label="Page navigation">
              {renderPagination()}
            </nav>
          </div>
        </table>
      </div>


    </div>
  );
}
