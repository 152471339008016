import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../../components/utils/loggedin';
import ViewSalesRepDetails from './account';
import { Helmet } from 'react-helmet';
import Cards from './cards';

export default function ViewSalesRep() {
    const location = useLocation();
    const navigate = useNavigate();
    const { token } = useAuth();
    const [salesRepData, setSalesRepData] = useState({});
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false)
    const [tab, setTab] = useState("details");
    const [count, setCount] = useState({
        totalCards: "",
        totalActivatedCards: "",
        totalNotActivatedCards: ""
    })


    function handleTab(tab) {
        setTab(tab);
    }
    useEffect(() => {
        const { salesrepId } = location.state;
        if (!salesrepId) {
            navigate('/clinics');
        }
        fetchSalesRepData();
    }, []);

    const fetchSalesRepData = async () => {
        try {
            const { salesrepId } = location.state;
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/salesrep/getsalesrep/${salesrepId}`, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE
                }
            });
            setSalesRepData(response.data);
        } catch (error) {
            console.error('Error fetching salesrep data:', error);
        }
    }



    const countCards = async () => {
        const { salesrepId } = location.state;

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/healthcard/salesrep/card/count/${salesrepId}`, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    "Authorization": `Bearer ${token}`,
                }
            })
            if (response.status === 200) {
                setCount(response.data);
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        countCards()
    }, [])

    const initiateVerification = async (phone) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/salesrep/initiatesalesrepverification`, {
                phone
            }, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE
                }
            });
            if (response.status === 200) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const verifySalesRep = async (e) => {
        e.preventDefault()
        console.log(otp, salesRepData.phone)
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/salesrep/verifysalesrep/${salesRepData?.id}`, {
                phone: salesRepData.phone,
                otp
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Signature": process.env.REACT_APP_API_SIGNATURE
                }
            });
            if (response.status === 200) {
                toast.success(response.data.message);
                fetchSalesRepData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <Helmet>
                <title>SalesReps View - SalesReps BharateClinics </title>
            </Helmet>
            <div className="row gy-6 gy-md-0">
                {loading ? (
                    // Loader UI
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <>
                        {/* User Sidebar */}
                        <div className="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0">
                            {/* User Card */}
                            <div className="card mb-6">
                                <div className="card-body pt-12">
                                    <div className="user-avatar-section">
                                        <div className="d-flex align-items-center flex-column">
                                            <img
                                                className="img-fluid rounded-3 mb-4"
                                                src={salesRepData.profile_img ? salesRepData.profile_img : "/img/avatars/1.png"}
                                                height={120}
                                                width={120}
                                                alt="User avatar"
                                            />
                                            <div className="user-info text-center">
                                                <h5>{salesRepData.first_name + ' ' + salesRepData.last_name}</h5>
                                                <span className="badge bg-label-success rounded-pill">
                                                    Sales Representative
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-around flex-wrap my-6 gap-0 gap-md-3 gap-lg-4">
                                        <div className="d-flex align-items-center me-5 gap-4">
                                            <div className="avatar">
                                                <div className="avatar-initial bg-label-primary rounded-3">
                                                    <i className="ri-check-line ri-24px" />
                                                </div>
                                            </div>
                                            <div>
                                                <h5 className="mb-0">{count.totalActivated}</h5>
                                                <span>Total Activated Cards</span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center gap-4">
                                            <div className="avatar">
                                                <div className="avatar-initial bg-label-primary rounded-3">
                                                    <i class="ri-pass-pending-line"></i>
                                                </div>
                                            </div>
                                            <div>
                                                <h5 className="mb-0">{count.totalNonActivated}</h5>
                                                <span>Total Not Activated Cards</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* /User Card */}
                        </div>
                        {/* /User Sidebar */}

                        {/* User Content */}
                        <div className="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1">
                            {/* User Tabs */}
                            <div className="nav-align-top">
                                <ul className="nav nav-pills flex-column flex-md-row mb-6 row-gap-2">
                                    <li className="nav-item">
                                        <button
                                            onClick={() => handleTab("details")}
                                            className={`btn nav-link ${tab === "details" && "active"}`}
                                        >
                                            <i className="ri-user-line me-2" />
                                            SalesReps Details
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button
                                            onClick={() => handleTab("cards")}
                                            className={`btn nav-link ${tab === "cards" && "active"}`}
                                        >
                                            <i className="ri-user-line me-2" />
                                            Alloted Cards
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            {/* /User Tabs */}

                            {tab === "details" &&
                                <ViewSalesRepDetails salesRepData={salesRepData} />}
                            {tab === "cards" && <Cards salesRepData={salesRepData} />}
                        </div>
                        {/* /User Content */}
                    </>
                )}
            </div>


        </>

    )
}
