import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../components/utils/loggedin";
import Account from "./account";
import Card from "./card";
import Appointment from "./appointment";
import { Helmet } from "react-helmet";

export default function ViewAdmin() {
  const [tab, setTab] = useState("account");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useAuth();
  const [userData, setUserData] = useState({});
  const [totalAppointments, setTotalAppointments] = useState(0);
  const [totalScheduled, setTotalScheduled] = useState(0);

  // Calculate age from date of birth
  const calculateAge = (dob) => {
    if (!dob) return "N/A"; // Return a default value if dob is undefined
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  useEffect(() => {
    const patientId = location.state?.patientId;

    if (!patientId) {
      navigate("/admins/patient");
      return;
    }
    fetchAdminData(patientId);
  }, [location.state, navigate]);

  const fetchAdminData = async (patientId) => {
    console.log(patientId)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/getuserbyid/${patientId}`,
        {
          headers: {
            Signature: process.env.REACT_APP_API_SIGNATURE,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setUserData(response.data);
      console.log(response.data); // Log the entire response data
    } catch (error) {
      console.error("Error fetching admin data:", error);
      setError("Error fetching admin data");
    }
  };


  const fetchAppointments = async (patientId) => {
    console.log(patientId)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/appointments/user/${patientId}`,
        {

          headers: {
            Signature: process.env.REACT_APP_API_SIGNATURE,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const appointments = response.data;

      setTotalAppointments(appointments.length);

      const scheduledCount = appointments.filter(
        (appointment) => appointment.status === "scheduled"
      ).length;
      setTotalScheduled(scheduledCount);
    } catch (err) {
      setError("Error fetching appointments");
    } finally {
      setLoading(false);
    }
  };



  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const user = userData.user || {};
  const userDetails = userData.user_details || {};

  function handleTab(tab) {
    setTab(tab);
  }

  return (
    <>
      <Helmet>
        <title>View Patient - Admin Bharateclinics</title>
      </Helmet>
      <div className="row gy-6 gy-md-0">
        {/* User Sidebar */}
        <div className="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0">
          {/* User Card */}
          <div className="card mb-6">
            <div className="card-body pt-12">
              <div className="user-avatar-section">
                <div className="d-flex align-items-center flex-column">
                  <img
                    className="img-fluid rounded-3 mb-4"
                    src={userDetails.profile_img ? userDetails.profile_img : "/img/avatars/1.png"} // Replace with actual image if needed
                    height={120}
                    width={120}
                    alt="User avatar"
                  />
                  <div className="user-info text-center">
                    <h5>{user.first_name + " " + user.last_name}</h5>
                  </div>
                </div>
              </div>



              <div className="info-container">
                <ul className="list-unstyled mb-6">
                  <li className="mb-2">
                    <span className="fw-medium text-heading me-2">Email:</span>
                    <span>{user.email}</span>
                  </li>
                  <li className="mb-2">
                    <span className="fw-medium text-heading me-2">Age:</span>
                    <span>{calculateAge(userDetails.dob)} years</span>
                  </li>
                  <li className="mb-2">
                    <span className="fw-medium text-heading me-2">Contact:</span>
                    <span>{user.phone}</span>
                  </li>
                  <li className="mb-2">
                    <span className="fw-medium text-heading me-2">Address:</span>
                    <span>{userDetails.address}</span>
                  </li>
                  <li className="mb-2">
                    <span className="fw-medium text-heading me-2">City:</span>
                    <span>{userDetails.city}</span>
                  </li>
                  <li className="mb-2">
                    <span className="fw-medium text-heading me-2">State:</span>
                    <span>{userDetails.state}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/*/ User Sidebar */}
        {/* User Content */}
        <div className="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1">
          {/* User Tabs */}
          <div className="nav-align-top">
            <ul className="nav nav-pills flex-column flex-md-row mb-6 row-gap-2">
              <li className="nav-item">
                <button
                  onClick={() => handleTab("account")}
                  className={`btn nav-link ${tab === "account" && "active"}`}
                >
                  <i className="ri-group-line me-2" />
                  Account
                </button>
              </li>

              <li className="nav-item">
                <button
                  onClick={() => handleTab("totalappointment")}
                  className={`btn nav-link ${tab === "totalappointment" && "active"
                    }`}
                >
                  <i className="ri-lock-2-line me-2" />
                  Appointments
                </button>
              </li>

              <li className="nav-item">
                <button
                  onClick={() => handleTab("card")}
                  className={`btn nav-link ${tab === "card" && "active"}`}
                >
                  <i className="ri-id-card-line me-2"></i>
                  Cards
                </button>
              </li>
            </ul>
          </div>
          {/*/ User Tabs */}
          {tab === "account" && <Account user={user} userDetails={userDetails} />}
          {tab === "totalappointment" && <Appointment user={user} />}
          {tab === "card" && <Card user={user} />}
        </div>
      </div>
    </>
  );
}
