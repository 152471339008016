import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../components/utils/loggedin";
import { toast } from "react-toastify";

const AddSalesRep = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        password: "",
        confirmPassword: "",
        address: "",
        city: "",
        state: "",
        zip: ""
    });
    const { token } = useAuth();
    const nextStep = (e) => {
        e.preventDefault();
        if (validateStep(step)) {
            setStep(step + 1);
        }
    };

    const prevStep = (e) => {
        e.preventDefault();
        setStep(step - 1);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const [errors, setErrors] = useState({});
    const [isLocating, setIsLocating] = useState(false);
    const [locationError, setLocationError] = useState("");

    const validateStep = (currentStep) => {
        const { first_name, last_name, phone, email, password, confirmPassword, address, city, state, zip } = formData;
        switch (currentStep) {
            case 1:
                if (!email || !password || !confirmPassword) {
                    toast.warning("All fields are required");
                    return false;
                }
                if (password !== confirmPassword) {
                    alert("Passwords do not match");
                    return false;
                }
                return true;
            case 2:
                if (!first_name || !last_name || !phone || !address || !city || !state || !zip) {
                    toast.warning("All fields are required");
                    return false;
                }
                return true;
            default:
                return true;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateStep(step)) {
            return;
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/salesrep/registerbyadmin`, formData, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    "Authorization": `Bearer ${token}` // replace with actual token management
                }
            });
            if (response.status === 201) {
                const phone = formData.phone;
                navigate("/sales/verify/otp", { state: { phone } });
                toast.success(response.data.message)
            }
        } catch (error) {
            console.error(error);
            toast.warning("Error registering sales");
        }
    };

    const renderFormFields = (fields) => {
        return fields.map(({ id, label, type, placeholder }) => (
            <div className="col-sm-6 mb-5" key={id}>
                <div className="form-floating form-floating-outline">
                    <input
                        type={type}
                        id={id}
                        name={id}
                        className="form-control"
                        placeholder={placeholder}
                        aria-label={label}
                        value={formData[id]}
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor={id}>{label}</label>
                </div>
            </div>
        ));
    };

    const fetchCurrentLocation = () => {
        setIsLocating(true);
        setLocationError("");

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;

                    fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`)
                        .then((response) => response.json())
                        .then((data) => {
                            const { address } = data;
                            setFormData((prevData) => ({
                                ...prevData,
                                address: `${address.road || ""}, ${address.suburb || ""}`,
                                city: address.city || address.town || address.village || "",
                                state: address.state || "",
                                zip: address.postcode || "",
                            }));
                            setIsLocating(false);
                        })
                        .catch((error) => {
                            console.error("Error fetching location data:", error);
                            setLocationError("Failed to fetch address from location.");
                            setIsLocating(false);
                        });
                },
                (error) => {
                    console.error("Geolocation error:", error);
                    setLocationError("Unable to access your location.");
                    setIsLocating(false);
                }
            );
        } else {
            setLocationError("Geolocation is not supported by your browser.");
            setIsLocating(false);
        }
    };

    return (
        <div className="authentication-wrapper authentication-cover">
            <div className="authentication-inner row m-0">
                <div className="d-flex justify-content-start authentication-bg p-5">
                    <div className="w-px-700 mt-lg-0 pt-lg-0 pt-4">
                        <div className="card mb-6">
                            <h5 className="card-header border border-bottom-6">Add SalesRep</h5>
                            <form className="card-body pt-2" onSubmit={handleSubmit}>
                                {/* 1. Account Details */}
                                <h6>1. Account Details</h6>
                                <div className="row g-6">
                                    {/* Email */}
                                    <div className="col-md-6 mb-5">
                                        <div className="form-floating form-floating-outline">
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                className={`form-control ${errors.email ? "is-invalid" : ""}`}
                                                placeholder="john.doe@example.com"
                                                value={formData.email}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="email">Email</label>
                                            {errors.email && (
                                                <div className="invalid-feedback" style={{ color: "red" }}>
                                                    {errors.email}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* Password */}
                                    <div className="col-md-6 mb-5">
                                        <div className="form-floating form-floating-outline">
                                            <input
                                                type="password"
                                                id="password"
                                                name="password"
                                                className={`form-control ${errors.password ? "is-invalid" : ""}`}
                                                placeholder="••••••••"
                                                value={formData.password}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="password">Password</label>
                                            {errors.password && (
                                                <div className="invalid-feedback" style={{ color: "red" }}>
                                                    {errors.password}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* Confirm Password */}
                                    <div className="col-md-6 mb-5">
                                        <div className="form-floating form-floating-outline">
                                            <input
                                                type="password"
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                className={`form-control ${errors.confirmPassword ? "is-invalid" : ""}`}
                                                placeholder="••••••••"
                                                value={formData.confirmPassword}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="confirmPassword">Confirm Password</label>
                                            {errors.confirmPassword && (
                                                <div className="invalid-feedback" style={{ color: "red" }}>
                                                    {errors.confirmPassword}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <hr className="my-6 mx-n4" />

                                {/* 2. Personal Information */}
                                <h6>2. Personal Information</h6>
                                <div className="row g-6">
                                    {/* First Name */}
                                    <div className="col-md-6 mb-5">
                                        <div className="form-floating form-floating-outline">
                                            <input
                                                type="text"
                                                id="first_name"
                                                name="first_name"
                                                className={`form-control ${errors.first_name ? "is-invalid" : ""}`}
                                                placeholder="John"
                                                value={formData.first_name}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="first_name">First Name</label>
                                            {errors.first_name && (
                                                <div className="invalid-feedback" style={{ color: "red" }}>
                                                    {errors.first_name}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* Last Name */}
                                    <div className="col-md-6 mb-5">
                                        <div className="form-floating form-floating-outline">
                                            <input
                                                type="text"
                                                id="last_name"
                                                name="last_name"
                                                className={`form-control ${errors.last_name ? "is-invalid" : ""}`}
                                                placeholder="Doe"
                                                value={formData.last_name}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="last_name">Last Name</label>
                                            {errors.last_name && (
                                                <div className="invalid-feedback" style={{ color: "red" }}>
                                                    {errors.last_name}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* Phone */}
                                    <div className="col-md-6 mb-5">
                                        <div className="form-floating form-floating-outline">
                                            <input
                                                type="tel"
                                                id="phone"
                                                name="phone"
                                                className={`form-control ${errors.phone ? "is-invalid" : ""}`}
                                                placeholder="123-456-7890"
                                                value={formData.phone}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="phone">Phone</label>
                                            {errors.phone && (
                                                <div className="invalid-feedback" style={{ color: "red" }}>
                                                    {errors.phone}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>

                                <hr className="my-6 mx-n4" />

                                {/* 3. Address Information */}
                                <h6>3. Address Information</h6>
                                <div className="row g-6">
                                    {/* Address */}
                                    <div className="col-md-6 mb-5">
                                        <div className="form-floating form-floating-outline">
                                            <input
                                                type="text"
                                                id="address"
                                                name="address"
                                                className={`form-control ${errors.address ? "is-invalid" : ""}`}
                                                placeholder="Address"
                                                value={formData.address}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="address">Address</label>
                                            {errors.address && (
                                                <div className="invalid-feedback" style={{ color: "red" }}>
                                                    {errors.address}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* City */}
                                    <div className="col-md-6 mb-5">
                                        <div className="form-floating form-floating-outline">
                                            <input
                                                type="text"
                                                id="city"
                                                name="city"
                                                className={`form-control ${errors.city ? "is-invalid" : ""}`}
                                                placeholder="City"
                                                value={formData.city}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="city">City</label>
                                            {errors.city && (
                                                <div className="invalid-feedback" style={{ color: "red" }}>
                                                    {errors.city}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* State */}
                                    <div className="col-md-6 mb-5">
                                        <div className="form-floating form-floating-outline">
                                            <input
                                                type="text"
                                                id="state"
                                                name="state"
                                                className={`form-control ${errors.state ? "is-invalid" : ""}`}
                                                placeholder="State"
                                                value={formData.state}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="state">State</label>
                                            {errors.state && (
                                                <div className="invalid-feedback" style={{ color: "red" }}>
                                                    {errors.state}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* ZIP Code */}
                                    <div className="col-md-6 mb-5">
                                        <div className="form-floating form-floating-outline">
                                            <input
                                                type="text"
                                                id="zip"
                                                name="zip"
                                                className={`form-control ${errors.zip ? "is-invalid" : ""}`}
                                                placeholder="ZIP Code"
                                                value={formData.zip}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="zip">ZIP Code</label>
                                            {errors.zip && (
                                                <div className="invalid-feedback" style={{ color: "red" }}>
                                                    {errors.zip}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* Use My Location Button */}
                                <div className="pt-3">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={fetchCurrentLocation}
                                        disabled={isLocating}
                                    >
                                        {isLocating ? "Locating..." : "Use My Location"}
                                    </button>
                                    {locationError && (
                                        <div className="alert alert-danger mt-3" role="alert">
                                            {locationError}
                                        </div>
                                    )}
                                </div>

                                <hr className="my-6 mx-n4" />

                                {/* 4. Bank Information */}
                                <h6>4. Bank Information</h6>
                                <div className="row g-6">
                                    {/* Bank Name */}
                                    <div className="col-md-6 mb-5">
                                        <div className="form-floating form-floating-outline">
                                            <input
                                                type="text"
                                                id="bank_name"
                                                name="bank_name"
                                                className={`form-control ${errors.bank_name ? "is-invalid" : ""}`}
                                                placeholder="Bank Name"
                                                value={formData.bank_name}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="bank_name">Bank Name</label>
                                            {errors.bank_name && (
                                                <div className="invalid-feedback" style={{ color: "red" }}>
                                                    {errors.bank_name}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* Account Number */}
                                    <div className="col-md-6 mb-5">
                                        <div className="form-floating form-floating-outline">
                                            <input
                                                type="text"
                                                id="account_number"
                                                name="account_number"
                                                className={`form-control ${errors.account_number ? "is-invalid" : ""}`}
                                                placeholder="Account Number"
                                                value={formData.account_number}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="account_number">Account Number</label>
                                            {errors.account_number && (
                                                <div className="invalid-feedback" style={{ color: "red" }}>
                                                    {errors.account_number}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* IFSC Code */}
                                    <div className="col-md-6 mb-5">
                                        <div className="form-floating form-floating-outline">
                                            <input
                                                type="text"
                                                id="ifsc_code"
                                                name="ifsc_code"
                                                className={`form-control ${errors.ifsc_code ? "is-invalid" : ""}`}
                                                placeholder="IFSC Code"
                                                value={formData.ifsc_code}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="ifsc_code">IFSC Code</label>
                                            {errors.ifsc_code && (
                                                <div className="invalid-feedback" style={{ color: "red" }}>
                                                    {errors.ifsc_code}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* Branch Name */}
                                    <div className="col-md-6 mb-5">
                                        <div className="form-floating form-floating-outline">
                                            <input
                                                type="text"
                                                id="branch_name"
                                                name="branch_name"
                                                className={`form-control ${errors.branch_name ? "is-invalid" : ""}`}
                                                placeholder="Branch Name"
                                                value={formData.branch_name}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="branch_name">Branch Name</label>
                                            {errors.branch_name && (
                                                <div className="invalid-feedback" style={{ color: "red" }}>
                                                    {errors.branch_name}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {/* Submit Button */}
                                <div className="mt-2">
                                    <button
                                        type="submit"
                                        className="btn btn-primary me-sm-3 me-1"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? "Submitting..." : "Submit"}
                                    </button>
                                    <button
                                        type="reset"
                                        className="btn btn-outline-secondary"
                                    // onClick={handleReset}
                                    // disabled={isSubmitting}
                                    >
                                        Reset
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default AddSalesRep;
