import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

export default function VerifyDoctorOTP() {
    const [otp, setOtp] = useState(Array(6).fill(''));
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const phone = location.state?.phone;
    const aadhaar_card_number = location.state?.aadhaar_card_number;

    useEffect(() => {
        if (!phone || !aadhaar_card_number) {
            navigate('/'); // Redirect to home if no phone number is found in state
        }
    }, [phone,aadhaar_card_number, navigate]);

    const handleChange = (e, index) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            // Automatically focus the next input
            if (index < otp.length - 1) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const otpCode = otp.join('');
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/doctor/verifyotp`, { aadhaar_card_number, otp: otpCode }, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE
                }
            });
            if (response.status === 200) {
                setMessage('OTP verified successfully');
                navigate('/doctors', { state: { admin: response.data.doctor } });
            }
        } catch (error) {
            setMessage('Invalid OTP or OTP expired');
        }
    };

    if (!phone) {
        return null; // Exit early or render an error message if no phone number is found
    }

    return (
        <div className="authentication-wrapper authentication-cover">
            {/* /Logo */}
            <div className="authentication-inner row m-0">
                {/* Two Steps Verification */}
                <div className="d-flex authentication-bg position-relative py-sm-12 px-12 py-6">
                    <div className="w-px-400 mx-auto pt-5 pt-lg-0">
                        <h4 className="mb-1">Two Step Verification 💬</h4>
                        <p className="text-start mb-6">
                            We sent a verification code to your mobile. Enter the code from the mobile in the field below.
                            {phone && (
                                <span className="fw-medium d-block mt-1 h6">******{phone.slice(-4)}</span>
                            )}
                        </p>
                        <p className="mb-0 fw-medium">Type your 6 digit security code</p>
                        {message && <p className="text-danger">{message}</p>}
                        <form id="twoStepsForm" onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <div className="auth-input-wrapper d-flex align-items-center justify-content-between numeral-mask-wrapper">
                                    {otp.map((digit, index) => (
                                        <input
                                            key={index}
                                            type="tel"
                                            id={`otp-input-${index}`}
                                            className="form-control auth-input h-px-50 text-center numeral-mask mx-sm-1 my-2"
                                            maxLength={1}
                                            value={digit}
                                            onChange={(e) => handleChange(e, index)}
                                            autoFocus={index === 0}
                                        />
                                    ))}
                                </div>
                            </div>
                            <button className="btn btn-primary d-grid w-100 mb-5" type="submit">
                                Verify my account
                            </button>
                            <div className="text-center">
                                Didn't get the code?
                                <a href="javascript:void(0);" onClick={() => {/* Resend OTP function */}}>Resend</a>
                            </div>
                        </form>
                    </div>
                </div>
                {/* /Two Steps Verification */}
            </div>
        </div>
    );
}
