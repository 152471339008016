import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../../components/utils/loggedin';

export default function EditFAQ({ fetchFAQs, faq }) {
    const { token } = useAuth();
    const [formData, setFormData] = useState({
        question: '',
        answer: '',
    });

    useEffect(() => {
        setFormData({
            question: faq?.question,
            answer: faq?.answer,
        });
    }, [faq]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/faq/${faq?.id}`, formData, {
                headers: {
                    Signature: `${process.env.REACT_APP_API_SIGNATURE}`,
                    Authorization: `Bearer ${token}`
                }
            });
            fetchFAQs();
            toast.success('FAQ Updated successfully!');
            document.getElementById('closeModal').click();
            document.getElementById('closeCanvas').click();
        } catch (error) {
            console.error('Error creating faq:', error);
            toast.error('An error occurred while creating the faq');
        }
    };

    return (
        <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="editFAQ"
            aria-labelledby="offcanvasAddTestimonialLabel"
        >
            <div className="offcanvas-header border-bottom">
                <h5 id="offcanvasAddTestimonialLabel" className="offcanvas-question">
                    Edit FAQ
                </h5>
                <button
                    type="button"
                    id="closeModal"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                />
            </div>
            <div className="offcanvas-body mx-0 flex-grow-0 h-100">
                <form
                    className="add-new-faq pt-0"
                    id="addNewFAQForm"
                    onSubmit={handleSubmit}
                >
                    <div className="form-floating form-floating-outline mb-3">
                        <input
                            type="text"
                            className="form-control"
                            id="add-faq-question"
                            placeholder="CEO"
                            name="question"
                            value={formData.question}
                            onChange={handleInputChange}
                            aria-label="Title"
                        />
                        <label htmlFor="add-faq-question">Title</label>
                    </div>
                    <div className="form-floating form-floating-outline mb-3">
                        <textarea
                            className="form-control"
                            id="add-faq-answer"
                            placeholder="Your answer here"
                            name="answer"
                            value={formData.answer}
                            onChange={handleInputChange}
                            aria-label="Quote"
                        />
                        <label htmlFor="add-faq-answer">Quote</label>
                    </div>
                    <hr />
                    <button
                        type="submit"
                        className="btn btn-success me-sm-3 me-1 data-submit"
                    >
                        Submit
                    </button>
                    <button
                        id='closeCanvas'
                        type="reset"
                        className="btn btn-outline-secondary"
                        data-bs-dismiss="offcanvas"
                    >
                        Cancel
                    </button>
                </form>
            </div>
        </div>
    );
}
