import { Link } from 'react-router-dom'
import AllSalesReps from './allsalesreps'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../components/utils/loggedin';
import { Helmet } from 'react-helmet';

export default function SalesReps() {
    const [salesreps, setSalesReps] = useState([]);
    const [loading, setLoading] = useState(true);
    const { token } = useAuth();
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/salesrep/getsalesrepbyadmin`, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Signature": process.env.REACT_APP_API_SIGNATURE
            }
        })
            .then(response => {
                setSalesReps(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching salesreps:', error);
                setLoading(false);
            });
    }, []);
    return (
        <>
            <Helmet>
                <title>SalesReps Page</title>
            </Helmet>
            <h2 className="mb-4"></h2>
            <div className="row g-6 mb-6">
                <div className="col-sm-6 col-xl-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div className="me-1">
                                    <p className="text-heading mb-1">Total SalesReps</p>
                                    <div className="d-flex align-items-center">
                                        <h4 className="mb-1 me-2">{salesreps.length}</h4>
                                    </div>

                                </div>
                                <div className="avatar">
                                    <div className="avatar-initial bg-label-primary rounded-3">
                                        <div className="ri-group-line ri-26px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div className="me-1">
                                    <p className="text-heading mb-1">Pending Verification</p>
                                    <div className="d-flex align-items-center">
                                    </div>
                                </div>
                                <div className="avatar">
                                    <div className="avatar-initial bg-label-danger rounded-3">
                                        <div className="ri-user-add-line ri-26px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div className="me-1">
                                    <p className="text-heading mb-1">Active SalesReps</p>
                                    <div className="d-flex align-items-center">
                                    </div>
                                </div>
                                <div className="avatar">
                                    <div className="avatar-initial bg-label-success rounded-3">
                                        <div className="ri-user-follow-line ri-26px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div className="me-1">
                                    <p className="text-heading mb-1">Pending Onboarding</p>
                                    <div className="d-flex align-items-center">
                                    </div>
                                </div>
                                <div className="avatar">
                                    <div className="avatar-initial bg-label-warning rounded-3">
                                        <div className="ri-user-search-line ri-26px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Users List Table */}
            <div className="card">
                <div className="card-header border-bottom d-flex justify-content-between">
                    <h5 className="card-title mb-0">SalesReps</h5>
                    <div className="add-new">
                        <Link
                            to='/sales/add'
                            className="btn btn-primary waves-effect waves-light"
                        >
                            <i className="ri-add-line me-0 me-sm-1 d-inline-block d-sm-none" />
                            <span className="d-none d-sm-inline-block">
                                {" "}
                                Add New Sales Rep{" "}
                            </span>
                        </Link>
                    </div>

                </div>
                <div className="card-datatable table-responsive">
                    <div
                        id="DataTables_Table_0_wrapper"
                        className="dataTables_wrapper dt-bootstrap5 no-footer"
                    >
                        {/* <div className="row p-4"> */}
                        {/* <div className="col-md-2 d-flex align-items-center justify-content-md-start justify-content-center"> */}
                        {/* <div className="dt-action-buttons mt-5 mt-md-0">
                                    <div className="dt-buttons btn-group flex-wrap">
                                        <div className="btn-group"> */}
                        {/* <div className="btn-group">
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary buttons-collection dropdown-toggle btn-outline-secondary waves-effect waves-light"
                                                    data-bs-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <span>
                                                        <span className="d-flex align-items-center">
                                                            <i className="ri-download-2-line ri-16px me-2" />{" "}
                                                            <span className="d-none d-sm-inline-block">Export</span>
                                                        </span>{" "}
                                                    </span>
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                    <a className="dropdown-item" href="#">
                                                        <i className="ri-file-pdf-line ri-16px me-2" /> PDF
                                                    </a>
                                                    <a className="dropdown-item" href="#">
                                                        <i className="ri-file-excel-2-line ri-16px me-2" /> Excel
                                                    </a>
                                                </div>
                                            </div> */}
                        {/* </div>{" "}
                                    </div>
                                </div> */}
                        {/* </div> */}
                        {/* <div className="col-md-10">
                                <div className="d-flex align-items-center justify-content-md-end justify-content-center">


                                </div>
                            </div> */}
                        {/* </div> */}
                        <AllSalesReps salesreps={salesreps} loading={loading} />
                    </div>
                </div>
            </div>
        </>

    )
}
