import React, { useEffect } from 'react'
import { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../components/utils/loggedin';
import { toast } from 'react-toastify';

export default function Security({ adminData }) {
    const { token } = useAuth();
    const [confirmPassword, setConfirmPassword] = useState("")
    const [password, setPassword] = useState("");


    const resetPassword = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) return toast.error('Password do not match');
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/admin/reset-password`,
                { id: adminData.id, password },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Signature": process.env.REACT_APP_API_SIGNATURE,
                        "Authorization": `Bearer ${token}`
                    }
                }
            )
            toast.success("Password change successfully");
        } catch (error) {
            toast.error("Failed to change password")
        }
    }

    return (
        <div className="card mb-6">
            <div className="card-body">
                <div className='d-flex justify-content-between mb-6'>

                    <small className="card-text text-uppercase text-muted small">
                        Security
                    </small>

                </div>
                <div className="mb-6">
                    <h5 className="">Change Password</h5>
                    <div className="">
                        <form onSubmit={(e) => resetPassword(e)}>
                            <div className="alert alert-warning alert-dismissible" role="alert">
                                <h5 className="alert-heading mb-1">
                                    Ensure that these requirements are met
                                </h5>
                                <span>Minimum 8 characters long, uppercase &amp; symbol</span>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="alert"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="row gx-5">
                                <div className="mb-3 col-12 col-sm-6 form-password-toggle">
                                    <div className="input-group input-group-merge">
                                        <div className="form-floating form-floating-outline">
                                            <input
                                                type="password"
                                                id="newPassword"
                                                name="newPassword"
                                                className="form-control "
                                                placeholder="············"
                                                required=""
                                                defaultValue=""
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                            <label htmlFor="newPassword">New Password</label>
                                            <div className="invalid-feedback" />
                                        </div>

                                    </div>
                                </div>
                                <div className="mb-3 col-12 col-sm-6 form-password-toggle">
                                    <div className="input-group input-group-merge">
                                        <div className="form-floating form-floating-outline">
                                            <input
                                                type="password"
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                className="form-control "
                                                placeholder="············"
                                                required=""
                                                defaultValue=""
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                            <label htmlFor="confirmPassword">Confirm New Password</label>
                                            <div className="invalid-feedback" />
                                        </div>

                                    </div>
                                </div>
                                <div>
                                    <button type="submit" className="btn btn-primary me-2">
                                        Change Password
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    )
}
