import axios from 'axios';
import React from 'react';
import Chart from 'react-apexcharts';
import { useEffect } from 'react';
import { useState } from 'react';

const CardsChartData = ({ cardCount, activatedCards, notActivatedCards }) => {
    console.log(cardCount)
    function getAppointmentPercentage(appointment) {
        if (cardCount) {
            return ((appointment / cardCount) * 100).toFixed(1);
        }
    }


    const chartsData = [
        {
            color: '#72e128', // Green color
            series: cardCount.completed_appointments ? getAppointmentPercentage(cardCount?.completed_appointments) : 0,
            label: 'Completed Appointment',
            tasks: cardCount ? cardCount?.completed_appointments : 0,
        },
        {
            color: '#fdb528', // Green color
            series: cardCount.upcoming_appointments ? getAppointmentPercentage(cardCount?.upcoming_appointments) : 0,
            label: 'Upcoming Appointment',
            tasks: cardCount ? cardCount?.upcoming_appointments : 0,
        },
        {
            color: '#ff4d49', // Green color
            series: cardCount.rejected_appointments ? getAppointmentPercentage(cardCount?.rejected_appointments) : 0,
            label: 'Rejected Appointment',
            tasks: cardCount ? cardCount?.rejected_appointments : 0,
        },
    ];

    const getOptions = (color) => ({
        chart: {
            height: 70,
            type: 'radialBar',
            sparkline: {
                enabled: true,
            },
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    size: '50%', // Adjust this to make the hollow size appropriate
                },
                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        offsetY: 5, // Adjust this to center the percentage vertically
                        fontSize: '15px',
                        fontWeight: '600',
                        color: color,
                        show: true,
                        formatter: function (val) {
                            return val + '%';
                        },
                    },
                },
                track: {
                    background: '#E0E0E0',
                    strokeWidth: '100%',
                },
                strokeWidth: '14',
            },
        },
        fill: {
            colors: [color],
        },
        stroke: {
            lineCap: 'round',
        },
        labels: ['Progress'],
    });

    return (
        <div className="col-12 col-xxl-4 col-md-6 mt-3">
            <div className="card h-100">
                <div className="card-header d-flex align-items-center justify-content-between">
                    <h5 className="card-title m-0 me-2"> Cards Overview</h5>
                </div>
                <div className="card-body">
                    {/* <p>Total number of Patients with active plan: {cardCount?.total_appointments}</p> */}
                    <ul className="p-0 m-0">
                        {chartsData.map((item, index) => (
                            <li className="d-flex mb-4" key={index}>
                                <div className="me-3">
                                    <Chart
                                        options={getOptions(item.color)}
                                        series={[item.series]}
                                        type="radialBar"
                                        height={80}
                                        width={80}
                                    />
                                </div>
                                <div className="row w-100 align-items-center">
                                    <div className="col-9">
                                        <div className="me-2">
                                            {/* <h6 className="mb-2">{item.label}</h6>
                                            <p className="mb-0 small">{item.tasks} Appointments</p> */}
                                        </div>
                                    </div>
                                    {/* <div className="col-3 text-end">
                    <button type="button" className="btn btn-sm btn-icon bg-label-secondary">
                      <i className="ri-arrow-right-s-line ri-20px scaleX-n1-rtl"></i>
                    </button>
                  </div> */}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CardsChartData;
